class SidebarsClicker {
    constructor() {
        this.side_favourites = document.querySelector('.js-side-favourites');
        this.side_cart = document.querySelector('.js-side-cart');
        this.side_menu = document.querySelector('#mobile-menu');
        this.mask = document.querySelector('#mask');
        this.menu_close = document.querySelector('.js-close-menu-clicker');
        this.activeElement = null;

        if (this.mask !== null) {
            this._closeSide = this._closeSide.bind(this);
            this.mask.addEventListener('click', this._closeSide);
        }

        if (this.menu_close !== null) {
            this._closeMenu = this._closeMenu.bind(this);
            this.menu_close.addEventListener('click', this._closeMenu);
        }
        this.selectorClick = this.selectorClick.bind(this);
    }
    selectorClick(e) {
        e.preventDefault();

        let target = e.target.closest('[data-target]').getAttribute('data-target');
        switch (target) {
            case 'menu': this._toggleMenu();
                break;
            case 'cart': this._toggleCart();
                break;
            case 'fav': this._toggleFav();
                break;
        }
    }
    _showMenu() {
        if (this.activeElement !== null && this.activeElement !== 'menu') {
            this._hideCart();
            this._hideFav();
        }
        if (!this.side_menu.classList.contains('toggled')) {
            this.activeElement = 'menu';
            this.side_menu.classList.add('toggled');
            this.mask.classList.add('shown');
            document.querySelector('.js-hamburger').classList.add("active");
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
        }
    }
    _showCart() {
        if (this.activeElement !== null && this.activeElement !== 'cart') {
            //console.log('1');
            this._hideMenu();
            this._hideFav();
        }
        if (!this.side_cart.classList.contains('side-cart--shown')) {
            //console.log('2');
            this.activeElement = 'cart';
            this.side_cart.classList.add('side-cart--shown');
            this.mask.classList.add('shown');
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
        }
    }
    _showFav() {
        if (this.activeElement !== null && this.activeElement !== 'fav') {
            this._hideMenu();
            this._hideCart();
        }
        if (!this.side_favourites.classList.contains('side-cart--shown')) {
            this.activeElement = 'fav';
            this.side_favourites.classList.add('side-cart--shown');
            this.mask.classList.add('shown');
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
        }
    }
    _hideMenu() {
        if (this.side_menu.classList.contains('toggled')) {
            this.side_menu.classList.remove('toggled');
            document.querySelector('.js-hamburger').classList.remove("active");
            //console.log('menu ',this.activeElement);
            if (this.activeElement === 'menu')
                this.mask.classList.remove('shown');
            this.activeElement = null;
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        }
    }
    _hideCart() {
        if (this.side_cart.classList.contains('side-cart--shown')) {
            this.side_cart.classList.remove('side-cart--shown');
            //console.log('Cart ',this.activeElement);
            if (this.activeElement === 'cart')
                this.mask.classList.remove('shown');
            this.activeElement = null;
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        }
    }
    _hideFav() {
        if (this.side_favourites.classList.contains('side-cart--shown')) {
            this.side_favourites.classList.remove('side-cart--shown');
            //console.log('Fav ', this.activeElement);
            if (this.activeElement === 'fav')
                this.mask.classList.remove('shown');
            this.activeElement = null;
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        }
    }
    _toggleMenu() {
        if (this.activeElement === null) {
            this._showMenu();
        } else if (this.activeElement === 'menu') {
            this._hideMenu();
        } else {
            this._showMenu();
        }
    }
    _toggleCart() {
        if (this.activeElement === null) {
            this._showCart();
        } else if (this.activeElement === 'cart') {
            this._hideCart();
        } else {
            this._showCart();
        }
    }
    _toggleFav() {
        if (this.activeElement === null) {
            this._showFav();
        } else if (this.activeElement === 'fav') {
            this._hideFav();
        } else {
            this._showFav();
        }
    }
    _closeSide(e) {
        e.preventDefault();

        this._hideMenu();
        this._hideCart();
        this._hideFav();
    }
    _closeMenu(e) {
        e.preventDefault();

        this._hideMenu();
    }
}
export default SidebarsClicker;