class Banners {
    constructor(selector) {
        this.selector = selector;
        this.type = this.selector.getAttribute('data-type');
        this.id = this.selector.getAttribute('data-id');

        this._getBannersData().then(response => {
            if(response){
                //console.log('Generate banner');
                let banner = (response.target !== null) ? document.createElement('a') : document.createElement('div');
                banner.classList.add('banner');
                if (response.type === 'image') {
                    if (response.content !== null) {
                        let image = document.createElement('img');
                        image.classList.add('banner__image');
                        image.setAttribute('src', response.content);

                        let image_mobile = document.createElement('img');
                        image_mobile.classList.add('banner__image', 'banner__image--mobile');
                        if (response.mobile !== null) {
                            image_mobile.setAttribute('src', response.mobile);
                        } else image_mobile.setAttribute('src', response.content);
                        banner.appendChild(image);
                        banner.appendChild(image_mobile);
                    }
                } else {
                    banner.classList.add('banner--background');
                    let title = document.createElement('div');
                    title.classList.add('banner__title', 'title', 'title--size-21', 'text-center', 'title--weight-600');
                    title.innerText = response.title;

                    let content = document.createElement('div');
                    content.classList.add('banner__content');
                    content.innerHTML = response.content;

                    banner.appendChild(title);
                    banner.appendChild(content);
                }
                if (response.target !== null) {
                    banner.setAttribute('href', response.target);
                }
                this.selector.appendChild(banner);
            } else {
                // console.log('no-results');
                // this._noResults();
            }
        }).catch(error => {
            // this._handleError(error);
        })
    }
    _getBannersData() {
        // const body_data = new FormData();
        // body_data.append('type', this.type);
        // body_data.append('id', this.id);
        // const body_params = new URLSearchParams(body_data);

        //console.log('123');

        let url = '/wp-json/netivo/v1/banners?type=' + this.type + '&id=' + this.id;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            },
            // body: body_params
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                //console.log(response);
                return response.json();
            }
        });
    }
}
export default Banners