class ShopSearch {
    constructor(selector) {
        this.selector = selector;
        this.input = this.selector.querySelector('[data-element="input"]');
        this.button = this.selector.querySelector('[data-element="button"]');
        this.products_button = this.selector.querySelector('[data-element="products-button"]');

        this.results = this.selector.querySelector('[data-element="results"]');
        this.others = this.selector.querySelector('[data-element="others"]');

        this.result_products = this.results.querySelector('[data-element="products"]');
        this.result_categories = this.results.querySelector('[data-element="categories"]');
        this.result_producents = this.results.querySelector('[data-element="producents"]');
        this.result_series = this.results.querySelector('[data-element="series"]');

        this.result_not_found = this.results.querySelector('[data-element="not-found"]');
        this.result_not_found_categories = this.results.querySelector('[data-element="not-found-categories"]');
        this.result_not_found_producents = this.results.querySelector('[data-element="not-found-producents"]');
        this.result_not_found_collections = this.results.querySelector('[data-element="not-found-collections"]');

        this.loader = this.selector.querySelector('[data-element="loader"]');
        this.loader_results = this.selector.querySelector('[data-element="loader-results"]');
        this.loader_products = this.selector.querySelector('[data-element="loader-products"]');

        this.is_input_clicked = false;

        this.controller = new AbortController();
        this.signal = this.controller.signal;

        this.run = false;
        this.loading_others = false;
        this.time = null;

        if (window.innerWidth <= 768) {
            if (this.selector.classList.contains('search-form--sticky')) {
                this.selector.querySelector('[data-element="results"]').style.width = 'calc(' + window.innerWidth + '- 30px)';
            }
        }

        this._inputEvent = this._inputEvent.bind(this);
        this._inputKeyUp = this._inputKeyUp.bind(this);
        this.input.addEventListener('click', this._inputEvent);
        // this.input.addEventListener('focus', this._inputEvent);
        this.input.addEventListener('keyup', this._inputKeyUp);

        this._productsButtonEvent = this._productsButtonEvent.bind(this);
        this.products_button.addEventListener('click', this._productsButtonEvent);

        this._clickDocument = this._clickDocument.bind(this);
        document.addEventListener('click', this._clickDocument);
    }
    _inputEvent() {
        let search_string = this.input.value;
        if (search_string.length >= 3) {
            this._showLoader();
            this.run = true;
            setTimeout(() => {
                this._doSearch(search_string);
            }, 500);
        } else {
            this.run = false;
            this.results.classList.remove('search-results--shown');
            this.controller.abort();
            this._clearHtml();
        }
        this.is_input_clicked = true;
    }
    _inputKeyUp() {
        clearTimeout(this.waitTimer);
        this.waitTimer = setTimeout(() => {
            this._delay(this._inputEvent(), 0);
        }, 500);
    }
    _productsButtonEvent(e) {
        e.preventDefault();

        let search_string = this.input.value;
        if (search_string !== '')
            window.location.href = '/?s=' + search_string + '&post_type=product';
    }
    _clickDocument(e) {
        if (e.target !== this.input) {
            this.results.classList.remove('search-results--shown');
            this._hideLoader();
            this.is_input_clicked = false;

            this.controller.abort();
        }
    }
    _showLoader() {
        this.loader.classList.add('loader--shown');
    }
    _hideLoader() {
        this.loader.classList.remove('loader--shown');
    }
    _showLoaderResults() {
        this.loader_results.classList.add('loader--shown');
    }
    _hideLoaderResults() {
        this.loader_results.classList.remove('loader--shown');
    }
    _showLoaderProducts() {
        this.loader_products.classList.add('loader--shown');
    }
    _hideLoaderProducts() {
        this.loader_products.classList.remove('loader--shown');
    }
    _doSearch(string) {
        this._clearHtml();

        let step = 0;
        let url = '/wp-admin/admin-ajax.php';

        this.controller = new AbortController();
        this.signal = this.controller.signal;

        const products_data = new FormData();
        products_data.append('action', 'nt_wenahome_search_products');
        products_data.append('product', string);

        const products_params = new URLSearchParams(products_data);

        fetch(url, {
            method: 'POST',
            signal: this.signal,
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: products_params,
        }).then((response) => {
            step++;

            this._checkStepCount(step);
            return response.text();
        }).then((html) => {
            let list = this.result_products.querySelector('[data-element="list"]');
            list.innerHTML = '';

            if (html !== null && html !== '') {
                list.insertAdjacentHTML('beforeend', html);
                this._hideLoaderProducts();
                this.result_products.style.display = 'block';
                this.results.classList.add('search-results--shown');
            } else {
                this._hideLoaderProducts();
                this.result_not_found.style.display = 'block';
                // this.result_products.style.display = 'none';
            }
        }).catch((error) => {
            console.error('Error:', error.message);
        });

        const categories_data = new FormData();
        categories_data.append('action', 'nt_wenahome_search_categories');
        categories_data.append('category', string);

        const categories_params = new URLSearchParams(categories_data);
        this._prepareOthers();

        fetch(url, {
            method: 'POST',
            signal: this.signal,
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: categories_params,
        }).then((response) => {
            step++;

            this._checkStepCount(step);
            return response.text();
        }).then((html) => {
            let menu = this.result_categories.querySelector('[data-element="menu"]');
            menu.innerHTML = '';

            if (html !== null && html !== '') {
                menu.insertAdjacentHTML('beforeend', html);
                this.result_categories.style.display = 'block';
                this.result_not_found_categories.style.display = 'none';
            } else {
                this.result_categories.style.display = 'block';
                this.result_not_found_categories.style.display = 'block';
                this.result_categories.querySelector('[data-element="menu"]').style.margin = '0';
            }
        }).catch((error) => {
            console.error('Error:', error.message);
        });

        const producent_data = new FormData();
        producent_data.append('action', 'nt_wenahome_search_producents');
        producent_data.append('producent', string);

        const producents_params = new URLSearchParams(producent_data);

        fetch(url, {
            method: 'POST',
            signal: this.signal,
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: producents_params,
        }).then((response) => {
            step++;
            this._checkStepCount(step);
            return response.text();
        }).then((html) => {
            let menu = this.result_producents.querySelector('[data-element="menu"]');
            menu.innerHTML = '';

            if (html !== null && html !== '') {
                menu.insertAdjacentHTML('beforeend', html);
                this.result_producents.style.display = 'block';
                this.result_not_found_producents.style.display = 'none';
            } else {
                this.result_producents.style.display = 'block';
                this.result_not_found_producents.style.display = 'block';
                this.result_producents.querySelector('[data-element="menu"]').style.margin = '0';
            }
        }).catch((error) => {
            console.error('Error:', error.message);
        });

        const series_data = new FormData();
        series_data.append('action', 'nt_wenahome_search_series');
        series_data.append('series', string);

        const series_params = new URLSearchParams(series_data);

        fetch(url, {
            method: 'POST',
            signal: this.signal,
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: series_params,
        }).then((response) => {
            step++;
            this._checkStepCount(step);
            return response.text();
        }).then((html) => {
            let menu = this.result_series.querySelector('[data-element="menu"]');
            menu.innerHTML = '';

            if (html !== null && html !== '') {
                menu.insertAdjacentHTML('beforeend', html);
                this.result_series.style.display = 'block';
                this.result_not_found_collections.style.display = 'none';
            } else {
                this.result_series.style.display = 'block';
                this.result_not_found_collections.style.display = 'block';
                this.result_series.querySelector('[data-element="menu"]').style.margin = '0';
            }
        }).catch((error) => {
            console.error('Error:', error.message);
        });
    }
    _clearHtml() {
        this.result_products.querySelector('[data-element="list"]').innerHTML = '';
        this._showLoaderProducts();
        this.result_categories.querySelector('[data-element="menu"]').innerHTML = '';
        this.result_producents.querySelector('[data-element="menu"]').innerHTML = '';
        this.result_series.querySelector('[data-element="menu"]').innerHTML = '';
        this.result_not_found.style.display = 'none';
    }
    _checkStepCount(count) {
        if (count === 1) {
            this._showLoaderResults();
        }
        if (count === 4) {
            this._hideLoader();
            this._hideLoaderResults();
            this._unprepareOthers();
        }
        if (!this.run) this._clearHtml();
    }
    _prepareOthers() {
        this.loading_others = true;
        this.others.classList.add('search-results__others--show-overlay');
    }
    _unprepareOthers() {
        this.others.classList.remove('search-results__others--show-overlay');
        this.loading_others = false;
    }
    _delay(callback, ms) {
        return function() {
            var context = this, args = arguments;
            clearTimeout(this.timer);
            this.timer = setTimeout(function () {
                callback.apply(context, args);
            }, ms || 0);
        };
    }
}
export default ShopSearch;