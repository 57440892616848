class SearchClicker {
    constructor(selector) {
        this.selector = selector;
        this.shopSearch = document.querySelector('.js-shop-search').parentNode;

        this._clickEvent = this._clickEvent.bind(this);
        this.selector.addEventListener('click', this._clickEvent);
    }
    _clickEvent(e) {
        e.preventDefault();

        //console.log(this.shopSearch);

        if (this.shopSearch.classList.contains('search-box-container--mobile-active')) {
            this.shopSearch.classList.remove('search-box-container--mobile-active');
        } else {
            this.shopSearch.classList.add('search-box-container--mobile-active');
        }
    }
}
export default SearchClicker;