class InspirationProducts {
    constructor(selector, cart_obj) {
        this.selector = selector;
        this.points = this.selector.querySelectorAll('[data-element="product-point"]');
        this.points_count = this.points.length;
        this.cart_obj = cart_obj;

        let counter = 0;
        this.points.forEach(point => {
            point.addEventListener('click', e => {
                e.preventDefault();
            });
            point.addEventListener('mouseover', () => {
                if (point.querySelector('.inspiration-point__box.loaded') === null) {
                    let product_id = point.getAttribute('product-id');

                    if (product_id !== null) {
                        this._getFromAPI(product_id).then(response => {
                            if(response !== null){
                                let box = this._generateProductBox(response, point);
                                point.innerHTML = '';
                                point.appendChild(box);
                            } else {
                                console.log('no-results');
                                // this._noResults();
                            }
                        }).catch(error => {
                            console.log(error);
                            // this._handleError(error);
                        });
                    }
                }
            });
        });
    }
    _getFromAPI(product_id){
        let url = '/wp-json/netivo/v1/inspirationproducts/' + product_id;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _generateProductBox(res, point) {

        let box = document.createElement('div');
        box.classList.add('inspiration-point__box', 'loaded');

        let product_title = document.createElement('a');
        product_title.classList.add('inspiration-point__box-title');
        product_title.setAttribute('href', res.permalink);
        product_title.innerText = res.title;

        let product_prices = document.createElement('div');
        product_prices.classList.add('inspiration-point__box-prices');

        let product_prices_cntr = document.createElement('div');
        product_prices_cntr.classList.add('inspiration-point__box-prices-cntr');

        let price_regular = null;
        if (res.price_regular !== null) {
            price_regular = document.createElement('div');
            price_regular.classList.add('price-regular');
            price_regular.innerHTML = res.price_regular;
        }

        let price_cat = null;
        if (res.price_cat !== null) {
            price_cat = document.createElement('div');
            price_cat.classList.add('price-cat');
            price_cat.innerHTML = res.price_cat;
        }

        let discount = null;
        if (res.discount !== null) {
            discount = document.createElement('div');
            discount.classList.add('inspiration-point__box-discount');
            discount.innerText = '-' + res.discount + '%';
        }

        let button_container = document.createElement('div');
        button_container.classList.add('inspiration-point__box-button-container');

        // let button = document.createElement('a');
        // button.classList.add('button', 'button--small', 'js-add-to-cart');
        // button.innerText = 'Do koszyka';
        // button.setAttribute('data-product-id', point.getAttribute('product-id'));
        //
        // if (this.cart_obj !== null) {
        //     button.addEventListener('click', this.cart_obj._addToCart);
        // }


        let button = document.createElement('a');
        button.classList.add('button', 'button--small');
        button.setAttribute('href', res.permalink);
        button.innerText = 'Zobacz';

        button.addEventListener('click', e => {
            window.location.href = e.target.getAttribute('href');
        });

        button_container.appendChild(button);

        if (res.price_cat !== '' && res.price_regular !== '') {
            if (price_cat !== null) {
                price_cat.classList.add('cross');
            }
        }

        if (res.price_cat_clear !== res.price_regular_clear) {
            if (price_cat !== null && res.price_cat_clear !== '') {
                product_prices_cntr.appendChild(price_cat);
            }
            if (price_regular !== null && res.price_regular_clear !== '') {
                product_prices_cntr.appendChild(price_regular);
            }
        } else {
            if (price_cat !== null) {
                price_cat.classList.remove('cross');
            }
            product_prices_cntr.appendChild(price_cat);
        }

        product_prices.appendChild(product_prices_cntr);
        if (res.discount !== null) {
            button_container.appendChild(discount);
        }

        box.appendChild(product_title);
        box.appendChild(product_prices);
        box.appendChild(button_container);
        //box.appendChild(button);

        return box;
    }
    _checkPoints(counter) {
        if (counter == this.points_count) {
            this.points.forEach(point => {
                point.classList.add('inspiration-point--shown');
            });
        }
    }
    _calculateBoxPosition(parent) {
        let container = parent.parentNode.parentNode;
    }
}
export default InspirationProducts;