class CategoryTabs {
    constructor(selector) {
        this.selector = selector;
        this.tabs = this.selector.querySelectorAll('[data-element]');

        if (this.tabs !== null) {
            this.tabs.forEach(tab => {
                let tmp_tab = tab;
                if (tmp_tab.classList.contains('special-active')) {
                    setTimeout(() => {
                        tmp_tab.classList.remove('special-active');
                    }, 2000);
                }

                let clicker = tab.querySelector('[data-click]');
                if (clicker !== null) {
                    this._singleClick = this._singleClick.bind(this);
                    clicker.addEventListener('click', this._singleClick);
                }
            });
        }
    }
    _singleClick(e) {
        e.preventDefault();

        let current_element = e.target.closest('[data-element]');
        if (current_element.classList.contains('active')) {
            current_element.classList.remove('active');
            let clicker = current_element.querySelector('[data-click]');
            if (clicker !== null) {
                clicker.innerText = '+';
            }
        } else if (!current_element.classList.contains('active')) {
            current_element.classList.add('active');
            let clicker = current_element.querySelector('[data-click]');
            if (clicker !== null) {
                clicker.innerText = '-';
            }
        }
    }
}
export default CategoryTabs;