class SideCart {
    constructor(selector, sideClicker) {
        this.selector = selector;

        this.button = this.selector.querySelector('[data-element="button"]');
        this.cart_content = this.selector.querySelector('[data-element="bag-content"]');
        this.total = this.selector.querySelector('[data-element="bag-total"]');
        this.no_products = this.selector.querySelector('[data-element="bag-no-products"]');
        this.summary = this.selector.querySelector('[data-element="bag-summary"]');
        this.cart_buttons = this.selector.querySelector('[data-element="bag-cart-buttons"]');
        this.qty = 0;
        this.reload_products_array = {};
        this.products = this.selector.querySelectorAll('[data-element="product"]');
        this.close_button = this.selector.querySelector('[data-element="close-button"]');
        this.url = '/wp-admin/admin-ajax.php';
        this.qty_loaded = true;

        this.global_modal = document.querySelector('.js-global-modal');
        this.global_modal_content = null;
        this.global_modal_close = null;

        if (this.global_modal !== null) {
            this.global_modal_content = this.global_modal.querySelector('[data-element="content"]');
            this.global_modal_close = this.global_modal.querySelector('[data-element="close"]');

            if (this.global_modal_close !== null) {
                this._closeGlobalModal = this._closeGlobalModal.bind(this);
                this.global_modal_close.addEventListener('click', this._closeGlobalModal);
            }
        }

        // if (this.button !== null) {
        //     this._clickButton = this._clickButton.bind(this);
        //     this.button.addEventListener('click', this._clickButton);
        // }

        if (this.close_button !== null) {
            this.close_button.addEventListener('click', sideClicker._closeSide);
        }

        this._removeProduct = this._removeProduct.bind(this);
        this._addToCart = this._addToCart.bind(this);
        this._addToCartWithQty = this._addToCartWithQty.bind(this);
        this._removeFromCart = this._removeFromCart.bind(this);

        if (this.qty_loaded === true) {
            if (this.cart_content !== null) {
                let increaseBtns = this.cart_content.querySelectorAll('[data-element="increase-qty"]');
                let decreaseBtns = this.cart_content.querySelectorAll('[data-element="decrease-qty"]');

                if (increaseBtns !== null) {
                    this._increaseProductQty = this._increaseProductQty.bind(this);
                    increaseBtns.forEach(btn => {
                        btn.addEventListener('click', this._increaseProductQty);
                    });
                }
                if (decreaseBtns !== null) {
                    this._decreaseProductQty = this._decreaseProductQty.bind(this);
                    decreaseBtns.forEach(btn => {
                        btn.addEventListener('click', this._decreaseProductQty);
                    });
                }
            }
            this.qty_loaded = false;
        }

        this._initActions();

        // this._scrollEvent = this._scrollEvent.bind(this);
        // document.addEventListener('scroll', this._scrollEvent);

        // this._clickDocument = this._clickDocument.bind(this);
        // document.addEventListener('click', this._clickDocument);
    }
    _initActions() {
        this.products.forEach(product => {
            let remove = product.querySelector('[data-element="product-remove"]');
            if (remove !== null) {
                remove.addEventListener('click', this._removeProduct);
            }
        });
    }
    // _scrollEvent(e) {
    //     if (this.selector.classList.contains('side-cart--shown')) {
    //         this.selector.classList.remove('side-cart--shown');
    //     }
    // }
    // _clickDocument(e) {
    //     if (e.target !== this.selector) {
    //         if (this.selector.classList.contains('side-cart--shown')) {
    //             this.selector.classList.remove('side-cart--shown');
    //         }
    //     }
    // }
    // _clickButton(e) {
    //     e.preventDefault();
    //
    //     if (this.cart_content !== null) {
    //         if (!this.cart_content.classList.contains('side-cart--shown')) {
    //             this.cart_content.classList.add('side-cart--shown');
    //         } else {
    //             this.cart_content.classList.remove('side-cart--shown');
    //         }
    //     }
    // }
    _closeButton(e) {
        e.preventDefault();

        if (this.selector.classList.contains('side-cart--shown')) {
            this.selector.classList.remove('side-cart--shown');
        }
    }
    _removeCartItem(product_id, object = null) {
        const body_data = new FormData();
        body_data.append('action', 'nt_wenahome_remove_product');
        body_data.append('product_id', product_id);

        const body_params = new URLSearchParams(body_data);
        fetch(this.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: body_params
        }).then((response) => response.json())
            .then((response) => {
                if(response.status === 'success'){
                    this._updateBag('cart-remove', product_id, object, response.data);
                } else {
                    this._display_modal(response.message, response.status, null, null);
                    object.classList.remove('side-cart__product--locked');
                }
            }).catch((error) => {
            console.error('Error:', error);
            this._display_modal(error.message, error.status, null, null);
            object.classList.remove('side-cart__product--locked');
        });
    }
    _removeProduct(e) {
        e.preventDefault();
        let product = e.target.parentNode;
        if (product !== null) {
            let product_id = product.getAttribute('data-product-id');
            if (product_id !== null && product_id !== '') {
                product.classList.add('side-cart__product--locked');
                setTimeout(() => {
                    this._removeCartItem(product_id, product);
                });
            }
        }
    }

    _addCartItem(product_id) {
        const body_data = new FormData();
        body_data.append('action', 'nt_wenahome_add_product');
        body_data.append('product_id', product_id);

        const body_params = new URLSearchParams(body_data);
        fetch(this.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: body_params
        }).then((response) => response.json())
            .then((response) => {
                if(response.status === 'success'){
                    this._updateBag('cart-add', product_id,null, response.data);
                    this._display_modal(response.message, response.status, response.data, 1);
                } else {
                    this._display_modal(response.message, response.status, null, null);
                }
            }).catch((error) => {
            console.error('Error:', error);
            this._display_modal(error.message, error.status, null, null);
        });
    }
    _addCartItemQty(product_id, quantity) {
        const body_data = new FormData();
        body_data.append('action', 'nt_wenahome_add_product_qty');
        body_data.append('product_id', product_id);
        body_data.append('quantity', quantity);

        const body_params = new URLSearchParams(body_data);
        fetch(this.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: body_params
        }).then((response) => response.json())
            .then((response) => {
                if(response.status === 'success'){
                    this._updateBag('cart-add', product_id,null, response.data);
                    this._display_modal(response.message, response.status, response.data, quantity);
                } else {
                    this._display_modal(response.message, response.status, null, null);
                }
            }).catch((error) => {
            console.error('Error:', error);
            this._display_modal(error.message, error.status, null, null);
        });
    }
    _increaseCartItem(product_id) {
        const body_data = new FormData();
        body_data.append('action', 'nt_wenahome_increase_qty_cart');
        body_data.append('product_id', product_id);

        const body_params = new URLSearchParams(body_data);

        fetch(this.url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: body_params
        }).then((response) => response.json())
            .then((response) => {
                if(response.status === 'success'){
                    this._updateBag('cart-increase', product_id,null, response.data);
                } else {
                    //console.log(response.message, response.status);
                }
            }).catch((error) => {
            //console.log(error.message, error.status);
        });
    }
    _decreaseCartItem(product_id) {
        const body_data = new FormData();
        body_data.append('action', 'nt_wenahome_decrease_qty_cart');
        body_data.append('product_id', product_id);

        const body_params = new URLSearchParams(body_data);

        fetch(this.url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: body_params
        }).then((response) => response.json())
            .then((response) => {
                if(response.status === 'success'){
                    this._updateBag('cart-decrease', product_id,null, response.data);
                } else {
                    //console.log(response.message, response.status);
                }
            }).catch((error) => {
            console.log(error.message, error.status);
        });
    }
    _updateBag(operation, product_id, object, data) {
        let product = null;
        let product_html = '<div class="side-cart__product" data-element="product" data-product="" data-product-id="">\n' +
            '                                <a href="" class="side-cart__product-remove" data-element="product-remove">×</a>\n' +
            '                                <img alt="" class="side-cart__product-image" data-element="product-image" src="" />\n' +
            '                                <div class="side-cart__product-data">\n' +
            '                                    <a href="" class="side-cart__product-link" data-element="product-link">\n' +
            '                                        <h3 class="side-cart__product-title" data-element="product-title"></h3>\n' +
            '                                    </a>\n' +
            '                                    <div class="side-cart__product-amount">\n' +
            '                                        Ilość: <span data-element="product-amount"></span>\n' +
            '                                        <span class="side-cart__product-type" data-element="product-type"></span>\n'+
            '                                        <span class="side-cart__product-meters" data-element="product-meters"></span>\n'+
            '                                        <span class="side-cart__product-controls" data-element="product-control">\n' +
            '                                           <a href="" class="side-cart__product-controls-btn" data-element="decrease-qty">-</a>\n' +
            '                                           <a href="" class="side-cart__product-controls-btn" data-element="increase-qty">+</a>\n' +
            '                                        </span>\n'+
            '                                    </div>\n' +
            '                                    <div class="side-cart__product-price" data-element="product-price"></div>\n' +
            '                                </div>\n' +
            '                            </div>';

        switch (operation) {
            case 'cart-add': {
                //console.log('Update bag after add to cart operation');

                product = this.cart_content.querySelector('[data-product-id="' + product_id + '"]');
                if (product === null) {
                    product = this._createHtmlFromString(product_html);
                }
                product.setAttribute('data-product', data.product.key);
                product.setAttribute('data-product-id', data.product.product.id);
                product.querySelector('[data-element="product-image"]').setAttribute('alt', data.product.product.name);
                product.querySelector('[data-element="product-image"]').setAttribute('src', data.product.product.image);
                product.querySelector('[data-element="product-link"]').setAttribute('href', data.product.product.link);
                product.querySelector('[data-element="product-title"]').innerHTML = data.product.product.name;
                product.querySelector('[data-element="product-amount"]').innerHTML = data.product.quantity;
                if (data.product.product.type === 'meters') {
                    product.querySelector('[data-element="product-type"]').innerHTML = 'opk.';
                } else {
                    product.querySelector('[data-element="product-type"]').innerHTML = 'szt.';
                }
                if (data.product.product.type === 'meters' && data.product.product.mib !== '') {
                    product.querySelector('[data-element="product-meters"]').innerHTML = '(' + ((data.product.quantity)*(data.product.product.mib)).toFixed(2) + 'm<sup>2</sup>)';
                }
                product.querySelector('[data-element="product-price"]').innerHTML = data.product.total;

                this.cart_content.appendChild(product);

                let product_counters = document.querySelectorAll('[data-element="product-counter"]');
                if (data.total.quantity > 0) {
                    if (product_counters.length > 0) {
                        product_counters.forEach(counter => {
                            counter.style.display = 'flex';
                            counter.innerHTML = data.total.quantity;
                        });
                    }
                } else {
                    if (product_counters.length > 0) {
                        product_counters.forEach(counter => {
                            counter.style.display = 'none';
                        });
                    }
                }

                this.total.innerHTML = data.total.price;
                this.qty = parseInt(data.total.quantity);
                break;
            }
            case 'cart-remove': {
                //console.log('Update bag after cart remove operation');
                if (object !== null) object.remove();
                else {
                    product = this.cart_content.querySelector('[data-product-id="' + product_id + '"]');
                    if (product !== null) {
                        product.remove();
                    }
                }
                let product_counters = document.querySelectorAll('[data-element="product-counter"]');
                if (data.total.quantity > 0) {
                    if (product_counters.length > 0) {
                        product_counters.forEach(counter => {
                            counter.style.display = 'flex';
                            counter.innerHTML = data.total.quantity;
                        });
                    }
                } else {
                    if (product_counters.length > 0) {
                        product_counters.forEach(counter => {
                            counter.style.display = 'none';
                        });
                    }
                }

                this.total.innerHTML = data.total.price;
                this.qty = parseInt(data.total.quantity);
                break;
            }
            case 'cart-reload': {
                //console.log('Update cart after cart reload operation');
                //console.log(this.reload_products_array);
                if (!this._isEqual(this.reload_products_array, data.products)) {
                    let products = this.cart_content.querySelectorAll('[data-product]');
                    if (products !== null) {
                        products.forEach(prod => {
                            prod.remove();
                        });
                    }

                    data.products.forEach(prd => {
                        product = this.cart_content.querySelector('[data-product-id="' + prd.id + '"]');
                        if (product === null) {
                            product = this._createHtmlFromString(product_html);
                        }



                        product.setAttribute('data-product', prd.key);
                        product.setAttribute('data-product-id', prd.id);
                        product.querySelector('[data-element="product-image"]').setAttribute('alt', prd.name);
                        product.querySelector('[data-element="product-image"]').setAttribute('src', prd.image);
                        product.querySelector('[data-element="product-link"]').setAttribute('href', prd.link);
                        product.querySelector('[data-element="product-title"]').innerHTML = prd.name;
                        product.querySelector('[data-element="product-amount"]').innerHTML = prd.quantity;
                        if (prd.type === 'meters') {
                            product.querySelector('[data-element="product-type"]').innerHTML = 'opk.';
                        } else {
                            product.querySelector('[data-element="product-type"]').innerHTML = 'szt.';
                        }
                        if (prd.type === 'meters' && prd.mib !== '') {
                            product.querySelector('[data-element="product-meters"]').innerHTML = '(' + ((prd.quantity)*(prd.mib)).toFixed(2) + 'm<sup>2</sup>)';
                        }
                        product.querySelector('[data-element="product-price"]').innerHTML = prd.total;

                        this.cart_content.append(product);
                    });

                    let product_counters = document.querySelectorAll('[data-element="product-counter"]');
                    if (data.quantity > 0) {
                        if (product_counters.length > 0) {
                            product_counters.forEach(counter => {
                                counter.style.display = 'flex';
                                counter.innerText = data.quantity;
                            });
                        }
                    } else {
                        if (product_counters.length > 0) {
                            product_counters.forEach(counter => {
                                counter.style.display = 'none';
                            });
                        }
                    }

                    this.total.innerHTML = data.total;
                    this.qty = parseInt(data.quantity);
                }
                this.reload_products_array = data.products;
                break;
            }
            case 'cart-increase': {
                //console.log('Update cart after increase operation');

                if (object !== null) object.remove();
                else {
                    product = this.cart_content.querySelector('[data-product-id="' + product_id + '"]');
                    if (product !== null) {
                        product.querySelector('[data-element="product-amount"]').innerText = data.product.quantity;
                        product.querySelector('[data-element="product-price"]').innerHTML = data.product.total;
                        if (data.product.type === 'meters' && data.product.mib !== '') {
                            product.querySelector('[data-element="product-meters"]').innerHTML = '(' + ((data.product.quantity)*(data.product.mib)).toFixed(2) + 'm<sup>2</sup>)';
                        }
                    }
                }

                let product_counters = document.querySelectorAll('[data-element="product-counter"]');
                if (data.total.quantity > 0) {
                    if (product_counters.length > 0) {
                        product_counters.forEach(counter => {
                            counter.style.display = 'flex';
                            counter.innerText = data.total.quantity;
                        });
                    }
                } else {
                    if (product_counters.length > 0) {
                        product_counters.forEach(counter => {
                            counter.style.display = 'none';
                        });
                    }
                }

                this.total.innerHTML = data.total.price;
                this.qty = parseInt(data.total.quantity);
                break;
            }
            case 'cart-decrease': {
                //console.log('Update cart after decrease operation');

                if (object !== null) object.remove();
                else {
                    product = this.cart_content.querySelector('[data-product-id="' + product_id + '"]');
                    if (product !== null) {
                        product.querySelector('[data-element="product-amount"]').innerText = data.product.quantity;
                        product.querySelector('[data-element="product-price"]').innerHTML = data.product.total;
                        if (data.product.type === 'meters' && data.product.mib !== '') {
                            product.querySelector('[data-element="product-meters"]').innerHTML = '(' + ((data.product.quantity)*(data.product.mib)).toFixed(2) + 'm<sup>2</sup>)';
                        }
                    }
                }

                let product_counters = document.querySelectorAll('[data-element="product-counter"]');
                if (data.total.quantity > 0) {
                    if (product_counters.length > 0) {
                        product_counters.forEach(counter => {
                            counter.style.display = 'flex';
                            counter.innerText = data.total.quantity;
                        });
                    }
                } else {
                    if (product_counters.length > 0) {
                        product_counters.forEach(counter => {
                            counter.style.display = 'none';
                        });
                    }
                }

                this.total.innerHTML = data.total.price;
                this.qty = parseInt(data.total.quantity);
                break;
            }
        }

        if (product !== null) {
            let remove_button = product.querySelector('[data-element="product-remove"]');
            let increase_button = product.querySelector('[data-element="increase-qty"]');
            let decrease_button = product.querySelector('[data-element="decrease-qty"]');

            if (remove_button !== null) {
                remove_button.addEventListener('click', this._removeFromCart);
            }
            if (increase_button !== null) {
                increase_button.addEventListener('click', this._increaseProductQty);
            }
            if (decrease_button !== null) {
                decrease_button.addEventListener('click', this._decreaseProductQty);
            }
        }

        if (this.qty === 0) {
            if (this.summary !== null) {
                this.summary.style.display = 'none';
            }
            if (this.cart_buttons !== null) {
                this.cart_buttons.style.display = 'none';
            }
            if (this.no_products !== null) {
                this.no_products.style.display = 'block';
            }
        } else {
            if (this.summary !== null) {
                this.summary.style.display = 'block';
            }
            if (this.cart_buttons !== null) {
                this.cart_buttons.style.display = 'flex';
            }
            if (this.no_products !== null) {
                this.no_products.style.display = 'none';
            }
        }
    }
    _increaseProductQty(e) {
        e.preventDefault();

        let product = e.target.closest('[data-element="product"]');
        //console.log(product);
        if (product !== null) {
            let product_id = product.getAttribute('data-product-id');
            if (product_id !== null && product_id !== '') {
                this._increaseCartItem(product_id);
            }
        }
    }
    _decreaseProductQty(e) {
        e.preventDefault();

        let product = e.target.closest('[data-element="product"]');
        //console.log(product);
        if (product !== null) {
            let product_id = product.getAttribute('data-product-id');
            let qty = parseInt(product.querySelector('[data-element="product-amount"]').innerText);
            if (product_id !== null && product_id !== '') {
                if (qty > 1) this._decreaseCartItem(product_id);
                else this._removeCartItem(product_id, product);
            }
        }
    }
    _removeFromCart(e) {
        e.preventDefault();
        let product_id = e.target.parentNode.getAttribute('data-product-id');
        if (product_id !== null && product_id !== '') {
            this._removeCartItem(product_id);
        }
    }
    _addToCart(e) {
        e.preventDefault();

        let product_id = e.target.getAttribute('data-product-id');
        //console.log(product_id);
        if (product_id !== null && product_id !== '') {
            this._addCartItem(product_id);
        }
    }
    _addToCartWithQty(e) {
        e.preventDefault();

        let product_id = e.target.getAttribute('data-product-id');
        let qty = e.target.closest('.js-sticky-sidebar').querySelector('.js-quantity').querySelector('[data-element="product-qty"]').value;
        if (product_id !== null && product_id !== '') {
            if (qty !== '') {
                this._addCartItemQty(product_id, qty);
            }
        }
    }
    _reloadCart() {
        const reload_data = new FormData();
        reload_data.append('action', 'nt_wenahome_reload_cart');

        const reload_params = new URLSearchParams(reload_data);

        fetch(this.url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: reload_params
        }).then((response) => response.json())
            .then((response) => {
                //console.log(response.status);
                if(response.status === 'success'){
                    this._updateBag('cart-reload', null,null, response.cart);
                } else {
                    //console.log(response.message, response.status);
                }
            }).catch((error) => {
            //console.log(error.message, error.status);
        });
    }
    _firstCartLoad() {
        const first_cart_data = new FormData();
        first_cart_data.append('action', 'nt_wenahome_first_load_cart');

        const first_cart_params = new URLSearchParams(first_cart_data);

        fetch(this.url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: first_cart_params
        }).then((response) => response.json())
            .then((response) => {
                //console.log(response.status);
                if(response.status === 'success'){
                    let product_html = '<div class="side-cart__product" data-element="product" data-product="" data-product-id="">\n' +
                        '                                <a href="" class="side-cart__product-remove" data-element="product-remove">×</a>\n' +
                        '                                <img alt="" class="side-cart__product-image" data-element="product-image" src="" />\n' +
                        '                                <div class="side-cart__product-data">\n' +
                        '                                    <a href="" class="side-cart__product-link" data-element="product-link">\n' +
                        '                                        <h3 class="side-cart__product-title" data-element="product-title"></h3>\n' +
                        '                                    </a>\n' +
                        '                                    <div class="side-cart__product-amount">\n' +
                        '                                        Ilość: <span data-element="product-amount"></span>\n' +
                        '                                        <span class="side-cart__product-type" data-element="product-type"></span>\n'+
                        '                                        <span class="side-cart__product-meters" data-element="product-meters"></span>\n'+
                        '                                        <span class="side-cart__product-controls" data-element="product-control">\n' +
                        '                                           <a href="" class="side-cart__product-controls-btn" data-element="decrease-qty">-</a>\n' +
                        '                                           <a href="" class="side-cart__product-controls-btn" data-element="increase-qty">+</a>\n' +
                        '                                        </span>\n'+
                        '                                    </div>\n' +
                        '                                    <div class="side-cart__product-price" data-element="product-price"></div>\n' +
                        '                                </div>\n' +
                        '                            </div>';

                    console.log(response.data.products);
                    response.data.products.forEach(prd => {
                        let product = this.cart_content.querySelector('[data-product-id="' + prd.id + '"]');
                        if (product === null) {
                            product = this._createHtmlFromString(product_html);
                        }

                        product.setAttribute('data-product', prd.key);
                        product.setAttribute('data-product-id', prd.id);
                        product.querySelector('[data-element="product-image"]').setAttribute('alt', prd.name);
                        product.querySelector('[data-element="product-image"]').setAttribute('src', prd.image);
                        product.querySelector('[data-element="product-link"]').setAttribute('href', prd.link);
                        product.querySelector('[data-element="product-title"]').innerHTML = prd.name;
                        product.querySelector('[data-element="product-amount"]').innerHTML = prd.quantity;

                        product.querySelector('[data-element="product-price"]').innerHTML = prd.total;

                        let remove_button = product.querySelector('[data-element="product-remove"]');
                        let increase_button = product.querySelector('[data-element="increase-qty"]');
                        let decrease_button = product.querySelector('[data-element="decrease-qty"]');

                        if (remove_button !== null) {
                            remove_button.addEventListener('click', this._removeFromCart);
                        }
                        if (increase_button !== null) {
                            increase_button.addEventListener('click', this._increaseProductQty);
                        }
                        if (decrease_button !== null) {
                            decrease_button.addEventListener('click', this._decreaseProductQty);
                        }

                        this.cart_content.append(product);
                    });
                    let product_counters = document.querySelectorAll('[data-element="product-counter"]');
                    if (response.data.quantity > 0) {
                        if (product_counters.length > 0) {
                            product_counters.forEach(counter => {
                                counter.style.display = 'flex';
                                counter.innerText = response.data.quantity;
                            });
                        }
                        this.no_products.style.display = 'none';
                    } else {
                        if (product_counters.length > 0) {
                            product_counters.forEach(counter => {
                                counter.style.display = 'none';
                            });
                        }
                        this.no_products.style.display = 'block';
                        this.summary.style.display = 'none';
                        this.cart_buttons.style.display = 'none';
                    }

                    this.total.innerHTML = response.data.total;
                    this.qty = parseInt(response.data.quantity);

                    if (response.data.total > 0) {
                        let product_counters = document.querySelectorAll('[data-element="product-counter"]');
                        if (product_counters !== null) {
                            product_counters.forEach(pc, () => {
                                pc.innerText = response.data.total;
                            });
                            pc.classList.add('product-counter--shown');
                        }
                    }
                } else {
                    //console.log(response.message, response.status);
                }
            }).catch((error) => {
            //console.log(error.message, error.status);
        });
    }
    _createHtmlFromString(string) {
        let div = document.createElement('div');
        div.innerHTML = string.trim();
        return div.firstChild;
    }
    _display_modal(message, type = 'success', data, product_qty = 1) {

        //console.log(data);

        let cart_url = window.nt_cart_url;
        let msg = document.createElement('div');
        msg.classList.add('modal__message');
        if (type === 'success') {
            let span = document.createElement('span');
            span.classList.add('title', 'title--size-20', 'text-center');
            span.innerHTML = message;

            let modal_product = document.createElement('div');
            modal_product.classList.add('modal__product');

            let modal_product_image = document.createElement('img');
            modal_product_image.classList.add('modal__product-image');
            modal_product_image.setAttribute('alt', data.product.product.name);
            modal_product_image.setAttribute('src', data.product.product.image);

            modal_product.appendChild(modal_product_image);

            let modal_product_data = document.createElement('div');
            modal_product_data.classList.add('modal__product-data');

            let modal_product_name = document.createElement('div');
            modal_product_name.classList.add('modal__product-name');
            modal_product_name.innerHTML = data.product.product.name;

            let modal_product_attr = document.createElement('div');
            modal_product_attr.classList.add('modal__product-attr');

            let product_price = 0;
            let final_mib = null;
            if(data.product.product.type === 'meters' && data.product.product.mib !== ''){
                final_mib = parseFloat(data.product.product.mib * product_qty);
                product_price = parseFloat(data.product.product.cprice) * final_mib;
            } else {
                product_price = parseFloat(parseFloat(data.product.product.cprice) * parseFloat(product_qty));
            }

            let modal_product_price = document.createElement('div');
            modal_product_price.classList.add('modal__product-price');
            modal_product_price.innerHTML = 'Cena: ';

            let price_amount = document.createElement('span');
            price_amount.classList.add('woocommerce-Price-amount', 'amount');
            price_amount.innerHTML = (product_price.toFixed(2));

            let price_currency = document.createElement('span');
            price_currency.classList.add('woocommerce-Price-currencySymbol');
            price_currency.innerHTML = '&#122;&#322;';

            price_amount.appendChild(price_currency);
            modal_product_price.appendChild(price_amount);

            let modal_product_amount = document.createElement('div');
            modal_product_amount.classList.add('modal__product-amount');
            modal_product_amount.innerHTML = 'Ilość: ' + product_qty + ((data.product.product.type === 'meters') ? ' opak.' : ' szt.') + ((data.product.product.type === 'meters' && data.product.product.mib !== '') ? '(' + final_mib.toFixed(2) + 'm<sup>2</sup>)' : '');

            modal_product_attr.appendChild(modal_product_price);
            modal_product_attr.appendChild(modal_product_amount);

            modal_product_data.appendChild(modal_product_name);
            modal_product_data.appendChild(modal_product_attr);

            modal_product.appendChild(modal_product_data);

            let modal_buttons = document.createElement('div');
            modal_buttons.classList.add('modal__buttons');

            let button_continue = document.createElement('a');
            button_continue.classList.add('button', 'button--continue-shop');
            button_continue.innerText = 'Wróć do zakupów';
            button_continue.setAttribute('title', 'Wróć do zakupów');
            button_continue.setAttribute('data-element', 'close');

            button_continue.addEventListener('click', e => {
                e.preventDefault();

                if (this.global_modal.classList.contains('modal--shown')) {
                    this.global_modal.classList.remove('modal--shown');
                }
            });

            modal_buttons.appendChild(button_continue);

            if (cart_url !== '') {
                let button_cart = document.createElement('a');
                button_cart.classList.add('button', 'button--cart');
                button_cart.innerText = 'Pokaż koszyk';
                button_cart.setAttribute('title', 'Przejdź do koszyka');
                button_cart.setAttribute('href', cart_url);
                modal_buttons.appendChild(button_cart);
            }
            msg.appendChild(span);
            msg.appendChild(modal_product);
            msg.appendChild(modal_buttons);
        } else if (type === 'error') {
            msg.classList.add('modal__message--error');

            let span = document.createElement('span');
            span.classList.add('title', 'title--size-20', 'text-center');
            span.innerHTML = message;
            msg.appendChild(span);
        }

        this.global_modal_content.innerHTML = '';
        this.global_modal_content.appendChild(msg);
        this.global_modal.classList.add('modal--shown');
    }
    _closeGlobalModal(e) {
        e.preventDefault();

        if (this.global_modal.classList.contains('modal--shown')) {
            this.global_modal.classList.remove('modal--shown');
        }
    }
    _isEqual(value, other) {
        let type = Object.prototype.toString.call(value);
        if (type !== Object.prototype.toString.call(other)) return false;
        if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

        let valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
        let otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
        if (valueLen !== otherLen) return false;

        let sc = this;
        let compare = function (item1, item2) {
            let itemType = Object.prototype.toString.call(item1);

            if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
                if (!sc._isEqual(item1, item2)) return false;
            } else {
                if (itemType !== Object.prototype.toString.call(item2)) return false;

                if (itemType === '[object Function]') {
                    if (item1.toString() !== item2.toString()) return false;
                } else {
                    if (item1 !== item2) return false;
                }
            }
        };

        if (type === '[object Array]') {
            for (let i = 0; i < valueLen; i++) {
                if (compare(value[i], other[i]) === false) return false;
            }
        } else {
            for (let key in value) {
                if (value.hasOwnProperty(key)) {
                    if (compare(value[key], other[key]) === false) return false;
                }
            }
        }
        return true;
    };
}
export default SideCart;