class BlogList {
    constructor(selector) {
        this.selector = selector;

        this.loadMore = this.selector.querySelector('[data-element="load-more"]');
        this.postContainer = this.selector.querySelector('[data-element="container"]');
        this.loadMorePoint = this.selector.querySelector('[data-element="load-more-point"]');
        this.loader = this.selector.querySelector('[data-element="loader"]');

        this.lastPage = false;
        this.isLoading = false;
        this.firstLoading = false;

        this.event = new Event('blogPostLoaded');

        this._nextPageEvent = this._nextPageEvent.bind(this);

        this._loadAttributes();

        this._initActions();

        if(this.loadOnInit){
            this.postContainer.innerHTML = '';
            this._loadPosts();
        }

        // let posts_count = this.postContainer.querySelectorAll('.blog-post');
        // if (posts_count.length < this.perPage) {
        //     this.lastPage = true;
        //     this._hideLoadMoreButton();
        // }
    }

    _loadAttributes(){
        let categories = this.selector.getAttribute('data-category');
        if(categories !== null && categories !== ''){
            this.categories = categories.split(',');
        } else this.categories = [];

        let search = this.selector.getAttribute('data-search');
        if(search !== null && search !== ''){
            this.search = search;
        } else this.search = '';

        let page = this.selector.getAttribute('data-page');
        if(page !== null && page !== ''){
            this.page = parseInt(page);
        } else this.page = 1;

        let perPage = this.selector.getAttribute('data-per-page');
        if(perPage !== null && perPage !== ''){
            this.perPage = parseInt(perPage);
        } else this.perPage = 10;

        let loadOnInit = this.selector.getAttribute('data-load-on-init');
        if(loadOnInit !== null && loadOnInit !== ''){
            this.loadOnInit = (loadOnInit === 'true');
        } else this.loadOnInit = false;

        let max = this.selector.getAttribute('data-max');
        if (max !== null && max !== '') {
            this.max = max;
        } else this.max = null;

        //console.log(this.max);
    }

    _initActions() {
        if (this.loadMore !== null) {
            this.loadMore.addEventListener('click', this._nextPageEvent);
        }
    }

    _nextPageEvent(event){
        event.preventDefault();
        if(!this.lastPage) {
            this._hideLoadMoreButton();
            this._nextPage();
        }
    }

    _nextPage(){
        this.page++;
        this._loadPosts();
    }

    _getFromAPI(){
        let queryString = this._buildQuery();
        let url = '/wp-json/wp/v2/posts?' + queryString;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

    _buildQuery(){
        let query = [
            'per_page=' + this.perPage,
            'page=' + this.page
        ];

        if(this.categories.length > 0){
            query.push('categories=' + this.categories.join(','));
        }

        if(this.search !== ''){
            query.push('search=' + this.search);
            query.push('orderby=relevance');
        }
        query.push('_embed');
        return query.join('&');
    }

    _loadPosts(){
        this._showLoaderAnimation();
        this.isLoading = true;
        this._getFromAPI().then(response => {
            if(response.length > 0){
                //console.log(response);
                this._generatePosts(response);
            } else {
                this._noResults();
            }
        }).catch(error => {
            this._handleError(error);
        })
    }

    _generatePosts(posts){
        posts.forEach((post, key) => {
            let postElement = this._generatePost(post);
            this.postContainer.append(postElement);
        });
        this._hideLoaderAnimation();
        if (this.postContainer.querySelectorAll('.blog-post').length !== this.max) {
            this._showLoadMoreButton();
        } else {
            this._hideLoadMoreButton();
        }
        this.isLoading = false;
    }

    _generatePost(post) {
        let blog_post = document.createElement('a');
        blog_post.classList.add('blog-post');
        blog_post.setAttribute('href', post.link);

        let image_container = document.createElement('div');
        image_container.classList.add('blog-post__image-container');

        let image = document.createElement('img');
        image.setAttribute('alt', post.title.rendered);
        image.setAttribute('src', post._embedded["wp:featuredmedia"][0].source_url);

        let post_content = document.createElement('div');
        post_content.classList.add('blog-post__content');

        let data_container = document.createElement('div');
        data_container.classList.add('blog-post__data');

        let date = document.createElement('div');
        date.classList.add('blog-post__date');
        date.innerText = post.nt_date;

        let author = document.createElement('div');
        author.classList.add('blog-post__author');

        let author_span = document.createElement('span');
        author_span.innerText = 'Autor: ' + post.nt_author;

        let title = document.createElement('h3');
        title.classList.add('blog-post__title');
        title.innerHTML = post.title.rendered;

        let categories = post.nt_categories;
        let categories_html = document.createElement('div');
        categories_html.classList.add('blog-post__categories');
        if (categories !== null) {
            categories.forEach(cat => {
                let cat_html = document.createElement('div');
                cat_html.classList.add('blog-post__category-item');
                cat_html.innerText = cat.name;
                categories_html.append(cat_html);
            });
        }

        let button = document.createElement('div');
        button.classList.add('blog-post__button');
        button.innerText = 'Czytaj więcej';

        image_container.appendChild(image);
        author.appendChild(author_span);
        data_container.appendChild(date);
        data_container.appendChild(author);
        post_content.appendChild(data_container);
        post_content.appendChild(title);
        post_content.appendChild(categories_html);
        post_content.appendChild(button);
        blog_post.appendChild(image_container);
        blog_post.appendChild(post_content);
        return blog_post;
    }

    _nt_excerpt(string, limit) {
        return string.split(" ").splice(0, limit).join(" ");
    }

    _noResults(){
        if(this.page === 1){
            let text = document.createElement('div');
            text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
            text.innerHTML = 'Ta kategoria nie posiada żadnych wpisów';
            this.postContainer.appendChild(text);
        } else {
            this.lastPage = true;
            this._hideLoadMoreButton();
        }
    }

    _handleError(error){
        // console.log(error);
        if (error.message === 'rest_post_invalid_page_number') {
            this.lastPage = true;
            this._hideLoadMoreButton();
        } else {
            let text = document.createElement('div');
            text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
            text.innerHTML = 'Ta kategoria nie posiada żadnych wpisów';
            this.postContainer.appendChild(text);
        }
        this._hideLoaderAnimation();
        this.isLoading = false;
    }

    _showLoaderAnimation() {
        if (this.firstLoading === false) {
            this.loader.classList.add('loader--absolute');
            this._hideLoadMoreButton();
        }
        this.loader.classList.add('loader--shown');

    }
    _hideLoaderAnimation() {
        if (this.firstLoading === false) {
            this.loader.classList.remove('loader--absolute');
            this._showLoadMoreButton();
            this.firstLoading = true;
        }
        this.loader.classList.remove('loader--shown');
    }
    _hideLoadMoreButton() {
        this.loadMore.style.display = 'none';
    }
    _showLoadMoreButton() {
        this.loadMore.style.display = 'inline-block';
    }
}
export default BlogList;