class Header {
    constructor(selector) {
        this.selector = selector;
        this.header_height = this.selector.offsetHeight;
        this.mobile_menu = document.querySelector('.js-mobile-menu');
        this.mask = document.querySelector('#mask');
        this.hamburger = document.querySelector('.js-hamburger');

        document.body.style.paddingTop = this.header_height + 'px';

        this._windowScroll = this._windowScroll.bind(this);
        window.addEventListener('scroll', this._windowScroll);
    }
    _windowScroll() {
        let from_top = this.selector.offsetTop;
        if (window.pageYOffset > from_top) {
            this.selector.classList.add('header--scrolled');
        } else {
            this.selector.classList.remove('header--scrolled');
            if (this.mobile_menu.classList.contains('toggled')) {
                this.mobile_menu.classList.remove('toggled');
                this.mask.classList.remove('shown');
                this.hamburger.classList.remove('active');
            }
        }
    }
}
export default Header;