class InspirationCheckboxes {
    constructor(selector) {
        this.selector = selector;

        this.inputHidden = this.selector.querySelector('input[type="hidden"]');
        this.checkboxes = this.selector.querySelectorAll('[data-element="checkbox"]');

        this._clickCheckbox = this._clickCheckbox.bind(this);

        if(this.checkboxes.length > 0) {
            this.checkboxes.forEach(element => {
                element.addEventListener('change', this._clickCheckbox);
            })
        }

    }

    _clickCheckbox(e) {
        e.preventDefault();
        let checked = [...this.selector.querySelectorAll('[data-element="checkbox"]:checked')].map((el) => {
            return el.value;
        }).join(',');
        this.inputHidden.value = checked;
    }

}
export default InspirationCheckboxes;