class MenuPromotion {
    constructor(selector, cart_obj) {
        this.selector = selector;
        this.contentLoaded = false;
        this.once = false;
        this.loader = this.selector.querySelector('[data-element="loader"]');
        this.cart_obj = cart_obj;

        let item = this.selector.closest('.menu-item');
        let category = this.selector.getAttribute('data-category');

        if (item !== null) {
            item.addEventListener('mouseover', e => {
                //console.log('Mouse over item');
                if (!item.classList.contains('f-e')) {
                    if (!this.contentLoaded) {
                        if (!this.once) {
                            this.loader.classList.add('loader--absolute', 'loader--shown');
                            this.once = true;

                            this._getProductData(category).then(response => {
                                if(response !== null){
                                    this._generateProductView(response);
                                } else {
                                    //console.log('Wystapił problem podczas próby załadowania danych')
                                    this.loader.classList.remove('loader--absolute', 'loader--shown');
                                }
                            }).catch(error => {
                                this.loader.classList.remove('loader--absolute', 'loader--shown');
                            });
                        }
                    }
                }
            });
        }
    }
    _getProductData(cat) {
        let url = '/wp-json/netivo/v1/categoryproduct/' + cat;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _generateProductView(res) {
        let content = document.createElement('div');
        content.classList.add('promotion-box__content');

        let grid = document.createElement('div');
        grid.classList.add('grid');

        let grid_left = document.createElement('div');
        grid_left.classList.add('grid__cell-1-5');

        let link = document.createElement('a');
        link.setAttribute('href', res.permalink);

        let img = document.createElement('img');
        img.setAttribute('alt', res.title);
        img.setAttribute('src', res.image);
        img.classList.add('promotion-box__image');

        let grid_right = document.createElement('div');
        grid_right.classList.add('grid__cell-6-14', 'padding-left', 'padding-left--small');

        let link_title = document.createElement('a');
        link_title.setAttribute('href', res.permalink);

        let title = document.createElement('div');
        title.classList.add('promotion-box__product-title');
        title.innerHTML = res.title;

        let product_price = document.createElement('div');
        product_price.classList.add('promotion-box__product-price');

        let regular_price = document.createElement('div');
        regular_price.classList.add('regular-price');

        let regular_price_amount = document.createElement('span');
        regular_price_amount.classList.add('amount_');
        regular_price_amount.innerHTML = res.price_regular;

        let product_type = document.createElement('div');
        product_type.classList.add('product-box__product-type');
        product_type.innerHTML = ' / ' + res.type;
        //console.log(product_type);

        let discount = document.createElement('div');
        discount.classList.add('discount');

        let discount_amount = document.createElement('span');
        discount_amount.classList.add('amount_');
        discount_amount.innerHTML = res.price_cat;

        let discount_percent = document.createElement('span');
        discount_percent.classList.add('procent');
        discount_percent.innerText = '-' + res.discount + '%';

        let button = document.createElement('a');
        button.classList.add('btn', 'btn--add-to-cart');

        // console.log(res.price, res.price > 0);
        if (res.price > 0) {
            if (res.status === 1) {
                button.setAttribute('href', res.cart_url);
                button.innerText = 'Do koszyka';
                button.classList.add('js-add-to-cart');
                button.setAttribute('data-product-id', res.id);

                if (this.cart_obj !== null) {
                    button.addEventListener('click', this.cart_obj._addToCart);
                }
            } else {
                button.setAttribute('href', res.permalink);
                button.innerText = 'Czytaj więcej';
            }
        } else {
            button.setAttribute('href', res.permalink);
            button.innerText = 'Czytaj więcej';
        }

        link.appendChild(img);
        grid_left.appendChild(link);

        discount.appendChild(discount_amount);
        discount.appendChild(discount_percent);

        regular_price.appendChild(regular_price_amount);
        regular_price.appendChild(product_type);

        if (res.price > 0) {
            product_price.appendChild(regular_price);
            if (res.discount > 0) {
                product_price.appendChild(discount);
            }
        }

        link_title.appendChild(title);

        grid_right.appendChild(link_title);
        grid_right.appendChild(product_price);
        grid_right.appendChild(button);

        grid.appendChild(grid_left);
        grid.appendChild(grid_right);

        content.appendChild(grid);

        this.loader.classList.remove('loader--shown', 'loader--absolute');
        this.selector.appendChild(content);
        this.contentLoaded = true;
    }
}
export default MenuPromotion;