class InspirationFilters {
    constructor(selector) {
        this.selector = selector;
        this.content = this.selector.querySelector('[data-element="content"]');
        this.button = this.selector.querySelector('[data-element="button"]');
        if (window.innerWidth <= 1000) {
            this.content.style.display = 'none';
            this.selector.classList.add('box--collapse');
            if (this.button !== null) {
                this._onClick = this._onClick.bind(this);
                this.button.addEventListener('click', this._onClick);
            }
        }
    }
    _onClick(e) {
        e.preventDefault();

        if (this.selector.classList.contains('box--collapse')) {
            this.selector.classList.remove('box--collapse');
            this.content.style.display = 'block';
            this.button.innerText = '(Zwiń)';
        } else {
            this.selector.classList.add('box--collapse');
            this.content.style.display = 'none';
            this.button.innerText = '(Rozwiń)';
        }
    }
}
export default InspirationFilters;