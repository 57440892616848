window.initMap = function() {
    var maps = document.getElementsByClassName('js-map');
    for(var j = 0; j<maps.length; j++){
        var el = maps[j];
        var lat = el.dataset.lat;
        var lng = el.dataset.lng;
        var zoom = (isNaN(parseInt(el.dataset.zoom))) ? 14 : el.dataset.zoom;
        var title = el.dataset.title;
        var content = el.dataset.content;
        var center = el.dataset.center;
        var icon = el.dataset.icon;

        if (lat !== '' && typeof lat !== 'undefined' && lng !== '' && typeof lng !== 'undefined') {
            lat = lat.split(';');
            lng = lng.split(';');

            if (lat.length === lng.length) {
                var centerlat =0;
                var centerlng =0;
                for (i = 0; i < lat.length; i++) {
                    centerlat += parseFloat(lat[i]);
                    centerlng += parseFloat(lng[i]);
                }
                centerlng = centerlng/lng.length;
                centerlat = centerlat/lat.length;
                if(typeof center !== 'undefined'){
                    centerlng -= 0.03;
                }
                // console.log(centerlng,centerlat);
                var myOptions = {
                    center: new google.maps.LatLng(centerlat, centerlng),
                    zoom: parseInt(zoom),
                    scrollwheel: false,
                    scaleControl: false,
                    disableDefaultUI: false,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };
                var map = new google.maps.Map(el, myOptions);
                if ((content !== '' && typeof content !== 'undefined' ) || (title !== '' && typeof title !== 'undefined')) {
                    content = content.split(';');
                    title = title.split(';');
                    icon = icon.split(';');
                    // console.log(icon);
                    if (title.length === lat.length) {
                        var marker = [];
                        for (var i = 0; i < lat.length; i++) {
                            var mkOpitons = {
                                icon: (icon[i] === '') ? icon[i] : '/wp-content/themes/amp/dist/images/map-pin.png',
                                position: new google.maps.LatLng(parseFloat(lat[i]), parseFloat(lng[i])),
                                map: map
                            };
                            if (title[i] !== '' && typeof title[i] !== 'undefined') {
                                mkOpitons.title = title[i];
                            }
                            marker[i] = new google.maps.Marker(mkOpitons);
                        }
                        var infowindow =[];
                        for (i = 0; i < lat.length; i++) {
                            if (content[i] !== '' && typeof content[i] !== 'undefined') {
                                infowindow[i] = new google.maps.InfoWindow({
                                    content: content[i]
                                });
                            }
                        }
                        // console.log(infowindow);
                        for (i = 0; i < lat.length; i++) {
                            if (content[i] !== '' && typeof content[i] !== 'undefined') {
                                (function(m, iw, mk, id){
                                    google.maps.event.addListener(mk[id],'click', function () {
                                        iw[id].open(m, mk[id]);
                                    });
                                })(map, infowindow, marker, i);
                            }
                        }
                    } else {
                        console.log('Niezgodna ilość punktów do naniesienia na mapę.');
                        el.innerHTML = "<p style='text-align: center; line-height:500px ; font-size:40px; margin: 0;' >Przepraszamy, nie możemy wyświetlić mapy</p>";
                    }
                }
            } else {
                console.log('Niezgodna ilość punktów do naniesienia na mapę.');
                el.innerHTML = "<p style='text-align: center; line-height:500px ; font-size:40px; margin: 0;' >Przepraszamy, nie możemy wyświetlić mapy</p>";
            }
        } else {
            console.log('Podaj poprawne atrybuty data - lat oraz lng');
            el.innerHTML = "<p style='text-align: center; line-height:500px ; font-size:40px; margin: 0;' >Przepraszamy, nie możemy wyświetlić mapy</p>";
        }
    }
}