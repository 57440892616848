import Splide from "@splidejs/splide";

class ProductTabs {
    constructor(selector, cart_obj) {
        this.selector = selector;
        this.fav_obj = null;
        this.cart_obj = cart_obj;
        this.list = this.selector.querySelector('[data-element="list"]');
        this.content = this.selector.querySelector('[data-element="content"]');
        this.tabs = this.list.querySelectorAll('[data-id]');
        this.targets = this.content.querySelectorAll('[data-target]');

        let breakpoints = {
            1000: {
                perPage: 4
            },
            810: {
                perPage: 3
            },
            580: {
                perPage: 2
            }
        };

        let bsCarousel = document.querySelector('.js-product-carousel-bestsellers');
        if (bsCarousel !== null) {
            const body_data = new FormData();
            body_data.append('action', 'nt_wenahome_product_bestsellers');

            const body_params = new URLSearchParams(body_data);
            fetch('/wp-admin/admin-ajax.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Cache-Control': 'no-cache',
                },
                body: body_params
            }).then((response) => response.text())
                .then((response) => {
                    let container = bsCarousel.querySelector('[data-container]');
                    let loader = bsCarousel.parentNode.querySelector('[data-element="loader"]');
                    let products_counter = 0;
                    if (container !== null) {
                        let html = this._createHtmlFromString(response);
                        for (var i = 0; i < html.length; i++) {
                            container.appendChild(html[i])
                        }
                        products_counter = container.querySelectorAll('.splide__slide').length;
                    }

                    if (window.innerWidth > 1000) {
                        if (products_counter < 5) {
                            bsCarousel.classList.add('product-carousel--no-controls');
                        }
                    } else if (window.innerWidth <= 1000) {
                        if (products_counter < 4) {
                            bsCarousel.classList.add('product-carousel--no-controls');
                        } else bsCarousel.classList.remove('product-carousel--no-controls');
                    } else if (window.innerWidth <= 810) {
                        if (products_counter < 3) {
                            bsCarousel.classList.add('product-carousel--no-controls');
                        } else bsCarousel.classList.remove('product-carousel--no-controls');
                    } else if (window.innerWidth <= 580) {
                        if (products_counter < 2) {
                            bsCarousel.classList.add('product-carousel--no-controls');
                        } else bsCarousel.classList.remove('product-carousel--no-controls');
                    }

                    loader.classList.remove('loader--shown');

                    new Splide(bsCarousel, {
                        perPage: 5,
                        rewind: true,
                        gap: 0,
                        pagination: false,
                        breakpoints: breakpoints
                    }).mount();

                    let afp_car = bsCarousel.querySelectorAll('.js-add-favourite-product');
                    if (afp_car !== null && this.fav_obj !== null) {
                        afp_car.forEach(button => {
                            button.addEventListener('click', this.fav_obj._addToFavourites);
                        });
                    }

                    let atc_car = bsCarousel.querySelectorAll('.js-add-to-cart');
                    if (atc_car.length > 0 && this.cart_obj !== null) {
                        atc_car.forEach(item => {
                            item.addEventListener('click', this.cart_obj._addToCart);
                        });
                    }
                }).catch((error) => {
                console.error('Error:', error);
            });
        }
        let promoCarousel = document.querySelector('.js-product-carousel-promotions');
        if (promoCarousel !== null) {
            const body_data = new FormData();
            body_data.append('action', 'nt_wenahome_product_promotions');

            const body_params = new URLSearchParams(body_data);
            fetch('/wp-admin/admin-ajax.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Cache-Control': 'no-cache',
                },
                body: body_params
            }).then((response) => response.text())
                .then((response) => {
                    let container = promoCarousel.querySelector('[data-container]');
                    let loader = promoCarousel.parentNode.querySelector('[data-element="loader"]');
                    let products_counter = 0;
                    if (container !== null) {
                        let html = this._createHtmlFromString(response);
                        for (var i = 0; i < html.length; i++) {
                            container.appendChild(html[i])
                        }
                        products_counter = container.querySelectorAll('.splide__slide').length;
                    }

                    if (window.innerWidth > 1000) {
                        if (products_counter < 5) {
                            promoCarousel.classList.add('product-carousel--no-controls');
                        }
                    } else if (window.innerWidth <= 1000) {
                        if (products_counter < 4) {
                            promoCarousel.classList.add('product-carousel--no-controls');
                        } else promoCarousel.classList.remove('product-carousel--no-controls');
                    } else if (window.innerWidth <= 810) {
                        if (products_counter < 3) {
                            promoCarousel.classList.add('product-carousel--no-controls');
                        } else promoCarousel.classList.remove('product-carousel--no-controls');
                    } else if (window.innerWidth <= 580) {
                        if (products_counter < 2) {
                            promoCarousel.classList.add('product-carousel--no-controls');
                        } else promoCarousel.classList.remove('product-carousel--no-controls');
                    }

                    loader.classList.remove('loader--shown');

                    new Splide(promoCarousel, {
                        perPage: 5,
                        rewind: true,
                        gap: 0,
                        pagination: false,
                        breakpoints: breakpoints
                    }).mount();

                    let afp_car = promoCarousel.querySelectorAll('.js-add-favourite-product');
                    if (afp_car !== null && this.fav_obj !== null) {
                        afp_car.forEach(button => {
                            button.addEventListener('click', this.fav_obj._addToFavourites);
                        });
                    }
                    let atc_car = promoCarousel.querySelectorAll('.js-add-to-cart');
                    if (atc_car.length > 0 && this.cart_obj !== null) {
                        atc_car.forEach(item => {
                            item.addEventListener('click', this.cart_obj._addToCart);
                        });
                    }
                }).catch((error) => {
                console.error('Error:', error);
            });
        }
        let newCarousel = document.querySelector('.js-product-carousel-new');
        if (newCarousel !== null) {
            const body_data = new FormData();
            body_data.append('action', 'nt_wenahome_product_new');

            const body_params = new URLSearchParams(body_data);
            fetch('/wp-admin/admin-ajax.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Cache-Control': 'no-cache',
                },
                body: body_params
            }).then((response) => response.text())
                .then((response) => {
                    let container = newCarousel.querySelector('[data-container]');
                    let loader = newCarousel.parentNode.querySelector('[data-element="loader"]');
                    let products_counter = 0;
                    if (container !== null) {
                        let html = this._createHtmlFromString(response);
                        for (var i = 0; i < html.length; i++) {
                            container.appendChild(html[i])
                        }
                        products_counter = container.querySelectorAll('.splide__slide').length;
                    }

                    if (window.innerWidth > 1000) {
                        if (products_counter < 5) {
                            newCarousel.classList.add('product-carousel--no-controls');
                        }
                    } else if (window.innerWidth <= 1000) {
                        if (products_counter < 4) {
                            newCarousel.classList.add('product-carousel--no-controls');
                        } else newCarousel.classList.remove('product-carousel--no-controls');
                    } else if (window.innerWidth <= 810) {
                        if (products_counter < 3) {
                            newCarousel.classList.add('product-carousel--no-controls');
                        } else newCarousel.classList.remove('product-carousel--no-controls');
                    } else if (window.innerWidth <= 580) {
                        if (products_counter < 2) {
                            newCarousel.classList.add('product-carousel--no-controls');
                        } else newCarousel.classList.remove('product-carousel--no-controls');
                    }

                    loader.classList.remove('loader--shown');

                    new Splide(newCarousel, {
                        perPage: 5,
                        rewind: true,
                        gap: 0,
                        pagination: false,
                        breakpoints: breakpoints
                    }).mount();

                    let afp_car = newCarousel.querySelectorAll('.js-add-favourite-product');
                    if (afp_car !== null && this.fav_obj !== null) {
                        afp_car.forEach(button => {
                            button.addEventListener('click', this.fav_obj._addToFavourites);
                        });
                    }
                    let atc_car = newCarousel.querySelectorAll('.js-add-to-cart');
                    if (atc_car.length > 0 && this.cart_obj !== null) {
                        atc_car.forEach(item => {
                            item.addEventListener('click', this.cart_obj._addToCart);
                        });
                    }
                }).catch((error) => {
                console.error('Error:', error);
            });
        }

        if (this.tabs !== null) {
            this.tabs.forEach(tab => {
                this._clickTab = this._clickTab.bind(this);
                tab.addEventListener('click', this._clickTab);
            });
        }
    }
    _clickTab(e) {
        e.preventDefault();

        let id = e.target.getAttribute('data-id');
        this._clearTabs();
        this._setActiveTab(id);
    }
    _clearTabs() {
        this.tabs.forEach(tab => {
            if (tab.classList.contains('product-tabs__list-item--active')) {
                tab.classList.remove('product-tabs__list-item--active');
            }
        });

        this.targets.forEach(target => {
            if (target.classList.contains('product-tabs__target--active')) {
                target.classList.remove('product-tabs__target--active')
            }
        });
    }
    _setActiveTab(id) {
        let tab = this.list.querySelector('[data-id="' + id + '"]');
        if (tab !== null) {
            tab.classList.add('product-tabs__list-item--active');
        }

        let target = this.content.querySelector('[data-target="' + id + '"]');
        if (target !== null) {
            target.classList.add('product-tabs__target--active')
        }
    }
    _createHtmlFromString(string) {
        let div = document.createElement('div');
        div.innerHTML = string.trim();
        return div.childNodes;
    }
}
export default ProductTabs;