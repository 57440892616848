class PromoBars {
    constructor(selector) {
        this.selector = selector;
        this.content = this.selector.querySelector('[data-element="content"]');
        this.type = this.selector.getAttribute('data-type');
        this.id = this.selector.getAttribute('data-id');

        this._getPromoBarsData().then(response => {
            if(response){
                if (response.class !== '') {
                    this.selector.classList.add('offer-bar--' + response.class);
                }
                this.content.innerHTML = response.content;
                this.selector.classList.add('offer-bar--shown');

                let header_height = document.querySelector('.js-header').offsetHeight;
                document.body.style.paddingTop = header_height + 'px';
            } else {
                // console.log('no-results');
                // this._noResults();
            }
        }).catch(error => {
            // this._handleError(error);
        })
    }
    _getPromoBarsData() {
        let url = '/wp-json/netivo/v1/promobars?type=' + this.type + '&id=' + this.id;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            },
            // body: body_params
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                //console.log(response);
                return response.json();
            }
        });
    }
}
export default PromoBars;