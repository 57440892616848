class ProductAvailable {
    constructor(selector) {
        this.selector = selector;
        this.modal = document.querySelector('.js-product-available-modal');
        this.close = this.modal.querySelector('[data-element="close"]');

        this._clickEvent = this._clickEvent.bind(this);
        this.selector.addEventListener('click', this._clickEvent);
        this._closeModal = this._closeModal.bind(this);

        if (this.close !== null) {
            this.close.addEventListener('click', this._closeModal);
        }
    }
    _clickEvent(e) {
        e.preventDefault();

        if (this.modal !== null) {
            if (this.modal.classList.contains('modal--product-available')) {
                if (!this.modal.classList.contains('modal--shown')) {
                    this.modal.classList.add('modal--shown');
                    document.body.classList.add('overlay');
                    document.body.style.overflow = 'hidden';
                    document.documentElement.style.overflow = 'hidden';
                }
            }
        }
    }
    _closeModal(e) {
        e.preventDefault();

        if (this.modal.classList.contains('modal--product-available')) {
            this.modal.classList.remove('modal--shown');
            document.body.classList.remove('overlay');
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        }
    }
}
export default ProductAvailable;