class ProductPackages {
    constructor(selector) {
        this.selector = selector;
        this.content = this.selector.querySelector('[data-element="content"]');
        this.button = this.selector.querySelector('[data-element="load-more"]');
        this.id = this.selector.getAttribute('data-id');
        this.page = 1;

        if (this.button !== null) {
            this._loadMore = this._loadMore.bind(this);
            this.button.addEventListener('click', this._loadMore);
        }

        this._getPackages();
    }
    _loadMore(e) {
        e.preventDefault();
        this._getPackages();
    }
    _getPackages() {
        const data = new FormData();
        data.append('action', 'nt_wenahome_product_packages');
        data.append('id', this.id);
        data.append('page', this.page);

        const params = new URLSearchParams(data);

        fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: params,
        }).then((response) => {
            return response.text();
        }).then((html) => {
            if (html === '') {
                this.selector.style.display = 'none';
            } else {
                this.content.insertAdjacentHTML('beforeend', html);
                if (this.page === 1) {
                    this.selector.style.display = 'block';
                }
                this.page++;
            }
        }).catch((error) => {
            console.error('Error:', error);
            this.selector.parentNode.style.display = 'none';
        });
    }
}
export default ProductPackages;