class MegaMenu {
    constructor(selector, cart_obj) {
        this.selector = selector;
        this.elements_with_submenus = this.selector.querySelectorAll('.navigation__element--has-submenu');
        this.cart_obj = cart_obj;

        if (this.elements_with_submenus !== null) {
            this.elements_with_submenus.forEach(submenu => {
                let sub_nav_cntr = submenu.querySelector('.sub-navigation-container');
                if (sub_nav_cntr !== null) {
                    let submenu = sub_nav_cntr.querySelector('.navigation--submenu');
                    if (submenu !== null) {
                        let first_element = submenu.querySelector('li:first-child');
                        first_element.classList.add('f-e');
                        first_element.classList.add('active');

                        let contentLoaded = false;
                        let once = false;
                        let loader = first_element.querySelector('[data-element="loader"]');
                        let promotionBox = first_element.querySelector('.js-promotion-box');
                        if (promotionBox !== null) {
                            let category = promotionBox.getAttribute('data-category');

                            if (!contentLoaded) {
                                if (!once) {
                                    loader.classList.add('shown');
                                    once = true;
                                    this._getProductData(category).then(response => {
                                        if(response !== null){
                                            this._generateProductView(response, loader, promotionBox);
                                        } else {
                                            //console.log('Wystapił problem podczas próby załadowania danych')
                                            loader.classList.remove('shown');
                                        }
                                    }).catch(error => {
                                        loader.classList.remove('shown');
                                    });
                                }
                            }
                        }

                        let li_elements = submenu.querySelectorAll('li');
                        li_elements.forEach(li => {
                            li.addEventListener('mouseover', e => {
                                this._clearSubmenuActiveElements(li_elements);
                                li.classList.add('active');
                            });
                            li.addEventListener('mouseout', e => {
                                if (!li.classList.contains('f-e')) {
                                    li.classList.remove('active');
                                }
                                this._setSubmenuFirstElementActive(submenu);
                            });
                        });
                    }
                }
            });
        }
    }
    _clearSubmenuActiveElements(submenu_elements) {
        submenu_elements.forEach(el => {
            if (el.classList.contains('active')) {
                el.classList.remove('active');
            }
        });
    }
    _setSubmenuFirstElementActive(submenu) {
        let first_element = submenu.querySelector('li:first-child');
        if (!first_element.classList.contains('active')) {
            first_element.classList.add('active');
        }
    }
    _getProductData(cat) {
        let url = '/wp-json/netivo/v1/categoryproduct/' + cat;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _generateProductView(res, loader, element) {
        let content = document.createElement('div');
        content.classList.add('promotion-box__content');

        let main_title = document.createElement('h2');
        main_title.classList.add('title', 'title--size-21');
        main_title.innerText = 'Polecamy';

        let grid = document.createElement('div');
        grid.classList.add('grid');

        let grid_left = document.createElement('div');
        grid_left.classList.add('grid__cell-1-3');

        let link = document.createElement('a');
        link.setAttribute('href', res.permalink);

        let img = document.createElement('img');
        img.setAttribute('alt', res.title);
        img.setAttribute('src', res.image);
        img.classList.add('promotion-box__image');

        let grid_right = document.createElement('div');
        grid_right.classList.add('grid__cell-4-14', 'padding-left', 'padding-left--small');

        let link_title = document.createElement('a');
        link_title.setAttribute('href', res.permalink);

        let title = document.createElement('div');
        title.classList.add('promotion-box__product-title');
        title.innerHTML = res.title;

        let product_price = document.createElement('div');
        product_price.classList.add('promotion-box__product-price');

        let regular_price = document.createElement('div');
        regular_price.classList.add('regular-price');

        let regular_price_amount = document.createElement('span');
        regular_price_amount.classList.add('amount_');
        regular_price_amount.innerHTML = res.price_regular;

        let discount = document.createElement('div');
        discount.classList.add('discount');

        let discount_amount = document.createElement('span');
        discount_amount.classList.add('amount_');
        discount_amount.innerHTML = res.price_catalog;

        let discount_percent = document.createElement('span');
        discount_percent.classList.add('procent');
        discount_percent.innerText = '-' + res.discount + '%';

        let button = document.createElement('a');
        button.classList.add('btn', 'btn--add-to-cart');

        //console.log(res.price, res.price > 0);
        if (res.price > 0) {
            if (res.status === 1) {
                button.setAttribute('href', res.cart_url);
                button.innerText = 'Do koszyka';

                button.classList.add('js-add-to-cart');
                button.setAttribute('data-product-id', res.id);
                if (this.cart_obj !== null) {
                    button.addEventListener('click', this.cart_obj._addToCart);
                }
            } else {
                button.setAttribute('href', res.permalink);
                button.innerText = 'Czytaj więcej';
            }
        } else {
            button.setAttribute('href', res.permalink);
            button.innerText = 'Czytaj więcej';
        }

        link.appendChild(img);
        grid_left.appendChild(link);

        regular_price.appendChild(regular_price_amount);
        discount.appendChild(discount_amount);
        discount.appendChild(discount_percent);

        if (res.price > 0) {
            if (res.discount > 0) {
                product_price.appendChild(discount);
            }
            product_price.appendChild(regular_price);
        }

        link_title.appendChild(title);

        grid_right.appendChild(link_title);
        grid_right.appendChild(product_price);
        grid_right.appendChild(button);

        grid.appendChild(grid_left);
        grid.appendChild(grid_right);

        content.appendChild(main_title);
        content.appendChild(grid);

        loader.classList.remove('shown');
        element.appendChild(content);
    }
}
export default MegaMenu;