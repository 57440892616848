class MobileNavigation {
    constructor(selector) {
        this.selector = selector;
        this.clickers = this.selector.querySelectorAll('[data-clicker]');
        //
        // let locked_elements = this.selector.querySelectorAll('li');
        // if (locked_elements.length > 0) {
        //     locked_elements.forEach(loc => {
        //         if (loc.classList.contains('locked')) {
        //             let link = loc.querySelector('a');
        //             console
        //             if (link !== null) {
        //                 link.addEventListener('click', (e) => {
        //                     e.preventDefault();
        //                 });
        //             }
        //         }
        //     });
        // }

        this.clickers.forEach(span => {
            span.addEventListener('click', e => {

                //console.log('clicked span ' + span.classList);
                e.preventDefault();

                if (!span.parentNode.classList.contains('active')) {
                    span.parentNode.classList.add('active');
                    if (span.parentNode.parentNode.querySelector('ul') !== null) {
                        span.parentNode.parentNode.querySelector('ul').classList.add('shown');
                    }
                    span.innerText = '-';
                } else {
                    span.parentNode.classList.remove('active');
                    if (span.parentNode.parentNode.querySelector('ul') !== null) {
                        span.parentNode.parentNode.querySelector('ul').classList.remove('shown');
                    }
                    span.innerText = '+';
                }
            });
        });

        let empty_menus = this.selector.querySelectorAll('ul.mobile-navigation--submenu-inside');
        if (empty_menus.length > 0) {
            empty_menus.forEach(menu => {
                let li_elements = menu.querySelectorAll('li');
                if (li_elements.length > 0) {
                    //console.log(li_elements.length);
                } else {
                    let parent = menu.parentNode;
                    if (parent !== null) {
                        let clicker = parent.querySelector('[data-clicker]');
                        if (clicker !== null) {
                            clicker.remove();
                        }
                    }
                }
            });
        }
    }
}
export default MobileNavigation;