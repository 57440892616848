class FrontContent {
    constructor(selector) {
        this.selector = selector;
        this.container = this.selector.querySelector('[data-element="container"]');
        this.button = this.selector.querySelector('[data-element="button"]');

        if (this.button !== null) {
            this._clickButton = this._clickButton.bind(this);
            this.button.addEventListener('click', this._clickButton);
        }

        if (window.innerWidth <= 767) {
            this.selector.classList.add('front-content--collapse');
            this.button.innerText = 'Rozwiń';
        }
    }
    _clickButton(e) {
        e.preventDefault();

        if (this.selector.classList.contains('front-content--collapse')) {
            this.selector.classList.remove('front-content--collapse');
            this.button.innerText = 'Zwiń';
        } else {
            this.selector.classList.add('front-content--collapse');
            this.button.innerText = 'Rozwiń';
        }
    }
}
export default FrontContent;