class ProductMovie {
    constructor(selector) {
        this.selector = selector;
        this.video_id = this.selector.getAttribute('data-video-id');
        this.video_button = this.selector.querySelector('[data-element="play-button"]');
        this.replace_me = this.selector.querySelector('[data-element="replace-me"]');

        if (this.video_id !== '' && this.video_id !== null) {
            if (this.video_button !== null) {
                this._prepareVideo = this._prepareVideo.bind(this);
                this.video_button.addEventListener('click', this._prepareVideo);
            }
        }
    }
    _prepareVideo(e) {
        e.preventDefault();

        let width = this.selector.offsetWidth;
        let height = this.selector.offsetHeight;

        let video_container = document.createElement('div');
        video_container.classList.add('product-movie__video-container');

        let video = document.createElement('iframe');
        video.setAttribute('width', width);
        video.setAttribute('height', height);
        video.setAttribute('src', 'https://www.youtube.com/embed/'+ this.video_id + '?rel=0&showinfo=0&autoplay=1');
        video.setAttribute('frameborder', 0);

        video_container.appendChild(video);

        this.replace_me.replaceWith(video_container);
    }
}
export default ProductMovie;