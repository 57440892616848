import { Form } from '@netivo/base-scripts';
import Splide from "@splidejs/splide";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import Pristine from "pristinejs/src/pristine";
import Cookies from "js-cookie";

import Slider from "./slider";
import ProductTabs from "./productTabs";
import FrontContent from "./frontContent";
import Inspirations from "./inspirations";
import InspirationProducts from "./inspirationProducts";
import BlogCategories from "./blogCategories";
import BlogList from "./blogList";
import CartClicker from "./cartClicker";
import SideCart from "./SideCart";
import CategoryDescription from "./categoryDescription";
import CategoryTabs from "./categoryTabs";
import StickySidebar from "./stickySidebar";
import ProductPackages from "./productPackages";
import CustomTabs from "./customTabs";
import QuantityInput from "./quantityInput";
import ProductMovie from "./productMovie";
import ShopSearch from "./shopSearch";
import Header from "./header";
import MenuPromotion from "./menuPromotion";
import MegaMenu from "./megaMenu";
import MobileNavigation from "./mobileNavigation";
import MobileFilterNavigation from "./mobileFilterNavigation";
import Filters from "./filters";
import SearchClicker from "./searchClicker";
import SidebarsClicker from "./sidebarsClicker";
import ProductVariations from "./productVariations";
import AskTrader from "./askTrader";
import Banners from "./banners";
import ProductAvailable from "./productAvailable";
import PromoBars from "./promoBars";
import CartNewsletter from "./cartNewsletter";
import HiddenFilters from "./hiddenFilters";
import map from './map';
import ProjectGrid from "./projectGrid";
import InspirationFilters from "./inspirationFilters";
import InspirationCheckboxes from "./inspirationCheckboxes";

// function toggleMenu(){
//     let menu = document.querySelector('#mobile-menu');
//     let mask = document.querySelector('#mask');
//     let wrapper = document.querySelector('#wrapper');
//     let content = document.querySelector('#content');
//     document.querySelector('.js-hamburger').classList.toggle("active");
//     // document.querySelector('#mask').toggle();
//     menu.classList.toggle("toggled");
//     mask.classList.toggle('shown');
//     content.classList.toggle("toggled");
//     if(menu.classList.contains("toggled")) {
//         // wrapper.css('height', menu.height() + 'px');
//         document.body.style.overflow = 'auto';
//         document.querySelector('html').style.overflow = 'auto';
//     }
//     else {
//         // wrapper.css('height', 'auto');
//         document.body.style.overflow = 'auto';
//         document.querySelector('html').style.overflow = 'auto';
//     }
// }
//
// let mask = document.querySelector('#mask');
// if (mask !== null) {
//     let menu = document.querySelector('#mobile-menu');
//     mask.addEventListener('click', (e) => {
//         mask.classList.toggle('shown');
//         menu.classList.toggle('toggled');
//         if(!menu.classList.contains("toggled")) {
//             document.body.style.overflow = 'auto';
//             document.querySelector('html').style.overflow = 'auto';
//         }
//     });
// }

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();

            menu.classList.remove('toggled');
            document.querySelector('.js-hamburger').classList.remove("active");
            mask.classList.remove('shown');
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        }
    });
}

window.addEventListener('DOMContentLoaded', () => {
    console.log('DOMContentLoaded');
    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 100;
        if (document.body.clientWidth <= 600) {
            window_top = document.body.scrollTop - 50;
        }
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) - 20;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);

    let sideClicker = new SidebarsClicker();
    let cart_clickers = document.querySelectorAll('.js-cart-clicker');
    if (cart_clickers !== null) {
        cart_clickers.forEach(clicker => {
            // new CartClicker(clicker);
            clicker.addEventListener('click', sideClicker.selectorClick);
        });
    }

    let hamburger = document.querySelector('.js-hamburger');
    if(hamburger !== null){
        hamburger.addEventListener('click', sideClicker.selectorClick);
    }

    let close_menu = document.querySelector('.js-close-menu-clicker');
    if (close_menu !== null) {
        close_menu.addEventListener('click', sideClicker.selectorClick);
    }

    let side_cart_obj = null;
    let side_cart = document.querySelector('.js-side-cart');
    if (side_cart !== null) {
        side_cart_obj = new SideCart(side_cart, sideClicker);
    }

    var cart_first_load = true;
    if (cart_first_load) {
        console.log('index cart first load');
        side_cart_obj._firstCartLoad();
        cart_first_load = false;
    }

    let promotion_boxes = document.querySelectorAll('.js-promotion-box');
    if (promotion_boxes !== null) {
        promotion_boxes.forEach(promo_box => {
            new MenuPromotion(promo_box, side_cart_obj);
        });
    }

    let product_packages = document.querySelector('.js-product-packages');
    if (product_packages !== null) {
        new ProductPackages(product_packages);
    }

    let mega_menu = document.querySelector('.js-navigation');
    if (mega_menu !== null) {
        new MegaMenu(mega_menu, side_cart_obj);
    }

    if (window.nt_cart_page === true) {
        setInterval(() => {
            side_cart_obj._reloadCart();
        }, 3000);
    }

    let atc = document.querySelectorAll('.js-add-to-cart');
    if (atc.length > 0 && side_cart_obj !== null) {
        atc.forEach(item => {
            //console.log(item);
            item.addEventListener('click', side_cart_obj._addToCart);
        });
    }

    let ats = document.querySelectorAll('.js-add-to-cart-single');
    if (ats.length > 0 && side_cart_obj !== null) {
        ats.forEach(item => {
            //console.log(item);
            item.addEventListener('click', side_cart_obj._addToCartWithQty);
        });
    }

    let slider = document.querySelector('.js-slider');
    if (slider !== null) {
        let slide = new Splide(document.querySelector('.js-slider'), {
            perPage: 1,
            rewind: true,
            type: 'fade',
            arrows: false,
            autoplay: true
        });
        slide.mount();
    }

    let producentsCarousel = document.querySelector('.js-producents');
    if (producentsCarousel !== null) {
        let breakpoints = {
            1000: {
                perPage: 4
            },
            810: {
                perPage: 3
            },
            767: {
                perPage: 2
            },
            580: {
                perPage: 2
            }
        };

        let options = {
            perPage: 6,
            rewind: true,
            gap: 0,
            pagination: false,
            breakpoints: breakpoints
        };

        new Splide(producentsCarousel, options).mount();
    }

    let productCarousels = document.querySelectorAll('.js-product-carousel');
    if (productCarousels !== null) {
        productCarousels.forEach(carousel => {
            let items_per_view = carousel.getAttribute('data-items-per-view');
            let type = carousel.getAttribute('data-carousel-type');
            let loader = carousel.parentNode.querySelector('[data-element="loader"]');

            let breakpoints = {
                1000: {
                    perPage: 4
                },
                810: {
                    perPage: 3
                },
                767: {
                    perPage: 2
                },
                580: {
                    perPage: 2
                }
            };

            let options = {
                perPage: (items_per_view !== null && items_per_view !== '') ? items_per_view : 5,
                rewind: true,
                gap: 0,
                pagination: false,
                breakpoints: breakpoints
            };

            if (type === 'category-products') {
                let category = carousel.getAttribute('data-category');
                if (category !== null) {

                    const body_data = new FormData();
                    body_data.append('action', 'nt_wenahome_products_by_category');
                    body_data.append('category', category);

                    const body_params = new URLSearchParams(body_data);
                    fetch('/wp-admin/admin-ajax.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Cache-Control': 'no-cache',
                        },
                        body: body_params
                    }).then((response) => response.text())
                        .then((response) => {
                            let container = carousel.querySelector('[data-container]');
                            let products_counter = 0;
                            if (container !== null) {
                                let html = createHtmlFromString(response);
                                for (var i = 0; i < html.length; i++) {
                                    container.appendChild(html[i])
                                }
                                products_counter = container.querySelectorAll('.splide__slide').length;
                            }

                            if (window.innerWidth > 1000) {
                                if (products_counter < items_per_view) {
                                    carousel.classList.add('product-carousel--no-controls');
                                }
                            } else if (window.innerWidth <= 1000) {
                                if (products_counter < 4) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 810) {
                                if (products_counter < 3) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 580) {
                                if (products_counter < 2) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            }

                            if (loader !== null) {
                                loader.classList.remove('loader--shown');
                            }

                            new Splide(carousel, options).mount();


                            let atc_car = carousel.querySelectorAll('.js-add-to-cart');
                            if (atc_car.length > 0 && side_cart_obj !== null) {
                                atc_car.forEach(item => {
                                    item.addEventListener('click', side_cart_obj._addToCart);
                                });
                            }

                        }).catch((error) => {
                        console.error('Error:', error);
                    });
                }
            } else if (type === 'inspiration-products' || type === 'single-post') {
                let products_ids = carousel.getAttribute('data-products-ids');
                if (products_ids !== '') {

                    const body_data = new FormData();
                    body_data.append('action', 'nt_wenahome_products_from_ids');
                    body_data.append('ids', products_ids);

                    const body_params = new URLSearchParams(body_data);
                    fetch('/wp-admin/admin-ajax.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Cache-Control': 'no-cache',
                        },
                        body: body_params
                    }).then((response) => response.text())
                        .then((response) => {
                            let container = carousel.querySelector('[data-container]');
                            let products_counter = 0;
                            if (container !== null) {
                                let html = createHtmlFromString(response);
                                for (var i = 0; i < html.length; i++) {
                                    container.appendChild(html[i])
                                }
                                products_counter = container.querySelectorAll('.splide__slide').length;
                            }
                            // console.log(products_counter, items_per_view);
                            if (window.innerWidth > 1000) {
                                if (products_counter < items_per_view) {
                                    carousel.classList.add('product-carousel--no-controls');
                                }
                            } else if (window.innerWidth <= 1000) {
                                if (products_counter < 4) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 810) {
                                if (products_counter < 3) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 580) {
                                if (products_counter < 2) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            }

                            if (loader !== null) {
                                loader.classList.remove('loader--shown');
                            }

                            new Splide(carousel, options).mount();


                            let atc_car = carousel.querySelectorAll('.js-add-to-cart');
                            if (atc_car.length > 0 && side_cart_obj !== null) {
                                atc_car.forEach(item => {
                                    item.addEventListener('click', side_cart_obj._addToCart);
                                });
                            }
                        }).catch((error) => {
                        console.error('Error:', error);
                    });
                }
            } else if (type === 'products-from-series') {
                let exclude_product_id = carousel.getAttribute('data-product-id');
                if (exclude_product_id !== '') {
                    const body_data = new FormData();
                    body_data.append('action', 'nt_wenahome_products_from_series');
                    body_data.append('exclude_product', exclude_product_id);

                    const body_params = new URLSearchParams(body_data);
                    fetch('/wp-admin/admin-ajax.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Cache-Control': 'no-cache',
                        },
                        body: body_params
                    }).then((response) => response.text())
                        .then((response) => {
                            let container = carousel.querySelector('[data-container]');
                            let products_counter = 0;
                            if (container !== null) {
                                let html = createHtmlFromString(response);
                                for (var i = 0; i < html.length; i++) {
                                    container.appendChild(html[i])
                                }
                                products_counter = container.querySelectorAll('.splide__slide').length;
                            }

                            breakpoints = {
                                580: {
                                    perPage: 2
                                },
                                767: {
                                    perPage: 3
                                },
                                850: {
                                    perPage: 2
                                },
                                1199: {
                                    perPage: 3
                                }
                            }

                            if (window.innerWidth > 1000) {
                                if (products_counter < items_per_view) {
                                    carousel.classList.add('product-carousel--no-controls');
                                }
                            } else if (window.innerWidth <= 1000) {
                                if (products_counter < 3) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 850) {
                                if (products_counter < 2) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 767) {
                                if (products_counter < 3) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 580) {
                                if (products_counter < 2) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            }

                            if (loader !== null) {
                                loader.classList.remove('loader--shown');
                            }

                            let prod_crsl = new Splide(carousel, {
                                perPage: (items_per_view !== null && items_per_view !== '') ? items_per_view : 5,
                                rewind: true,
                                gap: 0,
                                pagination: false,
                                breakpoints: breakpoints
                            }).mount();


                            let atc_car = carousel.querySelectorAll('.js-add-to-cart');
                            if (atc_car.length > 0 && side_cart_obj !== null) {
                                atc_car.forEach(item => {
                                    item.addEventListener('click', side_cart_obj._addToCart);
                                });
                            }
                        }).catch((error) => {
                        console.error('Error:', error);
                    });
                }
            } else if (type === 'upsell-products') {
                let exclude_product_id = carousel.getAttribute('data-product-id');
                if (exclude_product_id !== '') {
                    const body_data = new FormData();
                    body_data.append('action', 'nt_wenahome_products_from_series');
                    body_data.append('exclude_product', exclude_product_id);

                    const body_params = new URLSearchParams(body_data);
                    fetch('/wp-admin/admin-ajax.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Cache-Control': 'no-cache',
                        },
                        body: body_params
                    }).then((response) => response.text())
                        .then((response) => {
                            let container = carousel.querySelector('[data-container]');
                            let products_counter = 0;
                            if (container !== null) {
                                let html = createHtmlFromString(response);
                                //console.log(html);
                                for (var i = 0; i < html.length; i++) {
                                    container.appendChild(html[i])
                                }
                                products_counter = container.querySelectorAll('.splide__slide').length;
                            }

                            breakpoints = {
                                580: {
                                    perPage: 2
                                },
                                767: {
                                    perPage: 3
                                },
                                850: {
                                    perPage: 2
                                },
                                1199: {
                                    perPage: 3
                                }
                            }

                            if (window.innerWidth > 1000) {
                                if (products_counter < items_per_view) {
                                    carousel.classList.add('product-carousel--no-controls');
                                }
                            } else if (window.innerWidth <= 1000) {
                                if (products_counter < 3) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 850) {
                                if (products_counter < 2) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 767) {
                                if (products_counter < 3) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 580) {
                                if (products_counter < 2) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            }

                            if (loader !== null) {
                                loader.classList.remove('loader--shown');
                            }

                            let prod_crsl = new Splide(carousel, {
                                perPage: (items_per_view !== null && items_per_view !== '') ? items_per_view : 5,
                                rewind: true,
                                gap: 0,
                                pagination: false,
                                breakpoints: breakpoints
                            }).mount();



                            let atc_car = carousel.querySelectorAll('.js-add-to-cart');
                            if (atc_car.length > 0 && side_cart_obj !== null) {
                                atc_car.forEach(item => {
                                    item.addEventListener('click', side_cart_obj._addToCart);
                                });
                            }
                        }).catch((error) => {
                        console.error('Error:', error);
                    });
                }
            } else if (type === 'producent-products') {
                let producent = carousel.getAttribute('data-producent');
                if (producent !== null) {

                    const body_data = new FormData();
                    body_data.append('action', 'nt_wenahome_products_by_producent');
                    body_data.append('producent', producent);

                    const body_params = new URLSearchParams(body_data);
                    fetch('/wp-admin/admin-ajax.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Cache-Control': 'no-cache',
                        },
                        body: body_params
                    }).then((response) => response.text())
                        .then((response) => {
                            let container = carousel.querySelector('[data-container]');
                            let products_counter = 0;
                            if (container !== null) {
                                let html = createHtmlFromString(response);
                                for (var i = 0; i < html.length; i++) {
                                    container.appendChild(html[i])
                                }
                                products_counter = container.querySelectorAll('.splide__slide').length;
                            }

                            if (window.innerWidth > 1000) {
                                if (products_counter < items_per_view) {
                                    carousel.classList.add('product-carousel--no-controls');
                                }
                            } else if (window.innerWidth <= 1000) {
                                if (products_counter < 4) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 810) {
                                if (products_counter < 3) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 580) {
                                if (products_counter < 2) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            }

                            if (loader !== null) {
                                loader.classList.remove('loader--shown');
                            }

                            new Splide(carousel, options).mount();


                            let atc_car = carousel.querySelectorAll('.js-add-to-cart');
                            if (atc_car.length > 0 && side_cart_obj !== null) {
                                atc_car.forEach(item => {
                                    item.addEventListener('click', side_cart_obj._addToCart);
                                });
                            }

                        }).catch((error) => {
                        console.error('Error:', error);
                    });
                }
            } else if (type === 'collection-products') {
                let collection = carousel.getAttribute('data-collection');
                if (collection !== null) {

                    const body_data = new FormData();
                    body_data.append('action', 'nt_wenahome_products_by_collection');
                    body_data.append('collection', collection);

                    const body_params = new URLSearchParams(body_data);
                    fetch('/wp-admin/admin-ajax.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Cache-Control': 'no-cache',
                        },
                        body: body_params
                    }).then((response) => response.text())
                        .then((response) => {
                            let container = carousel.querySelector('[data-container]');
                            let products_counter = 0;
                            if (container !== null) {
                                let html = createHtmlFromString(response);
                                for (var i = 0; i < html.length; i++) {
                                    container.appendChild(html[i])
                                }
                                products_counter = container.querySelectorAll('.splide__slide').length;
                            }

                            if (window.innerWidth > 1000) {
                                if (products_counter < items_per_view) {
                                    carousel.classList.add('product-carousel--no-controls');
                                }
                            } else if (window.innerWidth <= 1000) {
                                if (products_counter < 4) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 810) {
                                if (products_counter < 3) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            } else if (window.innerWidth <= 580) {
                                if (products_counter < 2) {
                                    carousel.classList.add('product-carousel--no-controls');
                                } else carousel.classList.remove('product-carousel--no-controls');
                            }

                            if (loader !== null) {
                                loader.classList.remove('loader--shown');
                            }

                            new Splide(carousel, options).mount();


                            let atc_car = carousel.querySelectorAll('.js-add-to-cart');
                            if (atc_car.length > 0 && side_cart_obj !== null) {
                                atc_car.forEach(item => {
                                    item.addEventListener('click', side_cart_obj._addToCart);
                                });
                            }

                        }).catch((error) => {
                        console.error('Error:', error);
                    });
                }
            } else {
                let prod_crsl = new Splide(carousel, {
                    perPage: (items_per_view !== null && items_per_view !== '') ? items_per_view : 5,
                    rewind: true,
                    gap: 0,
                    pagination: false,
                    breakpoints: {
                        580: {
                            perPage: 2
                        },
                        767: {
                            perPage: 3
                        },
                        1000: {
                            perPage: 3
                        }
                    }
                }).mount();
            }
        });
    }

    let product_tabs = document.querySelector('.js-product-tabs');
    if (product_tabs !== null) {
        new ProductTabs(product_tabs, side_cart_obj);
    }

    let front_contents = document.querySelectorAll('.js-front-content');
    if (front_contents !== null) {
        front_contents.forEach(content => {
            new FrontContent(content);
        });
    }

    let inspirations = document.querySelector('.js-inspirations');
    if (inspirations !== null) {
        new Inspirations(inspirations);
    }

    let inspiration = document.querySelector('.js-inspiration');
    if (inspiration !== null) {
        new InspirationProducts(inspiration, side_cart_obj);
    }

    let blog_categories = document.querySelector('.js-blog-categories');
    if (blog_categories !== null) {
        new BlogCategories(blog_categories);
    }

    let blog_list = document.querySelector('.js-blog-list');
    if (blog_list !== null) {
        new BlogList(blog_list);
    }

    let search_clicker = document.querySelector('.js-search-clicker');
    if (search_clicker !== null) {
        new SearchClicker(search_clicker);
    }

    let category_description = document.querySelector('.js-category-description');
    if (category_description !== null) {
        new CategoryDescription(category_description);
    }

    let category_tabs = document.querySelectorAll('.js-category-tabs');
    if (category_tabs !== null) {
        category_tabs.forEach(ct => {
            new CategoryTabs(ct);
        });
    }

    let sticky_sidebar = document.querySelector('.js-sticky-sidebar');
    if (sticky_sidebar !== null) {
        new StickySidebar(sticky_sidebar);
    }

    let product_gallery = document.querySelector('.js-product-gallery');
    if (product_gallery !== null) {
        let gallery = new Splide(product_gallery, {
            rewind: true,
            pagination: false,
            arrows: false
        });

        let ps_options = {
            gallery: '.js-product-gallery',
            children: '.splide__slide a',
            pswpModule: PhotoSwipe,
            closeElClasses: ['item','caption', 'zoom-wrap', 'ui', 'top-bar'],
        };
        const lightbox = new PhotoSwipeLightbox(ps_options);
        lightbox.init();

        let product_gallery_thumbnails = document.querySelector('.js-product-gallery-thumbnails');
        if (product_gallery_thumbnails !== null) {
            let thumbnails = new Splide(product_gallery_thumbnails, {
                fixedWidth: 64,
                fixedHeight: 64,
                gap: 10,
                rewind: true,
                pagination: false,
                arrows: false,
                isNavigation: true
            });

            gallery.sync(thumbnails);
            gallery.mount();
            thumbnails.mount();
        }
    }

    let insp_options = {
        gallery: '.js-inspiration-gallery',
        children: 'a',
        pswpModule: PhotoSwipe,
        closeElClasses: ['item','caption', 'zoom-wrap', 'ui', 'top-bar'],
        hideAnimationDuration:0,
        showAnimationDuration: 0
    };
    const insp_lightbox = new PhotoSwipeLightbox(insp_options);
    insp_lightbox.init();

    let projects_options = {
        gallery: '.js-project-gallery',
        children: '.projects-grid__item a',
        pswpModule: PhotoSwipe,
        closeElClasses: ['item','caption', 'zoom-wrap', 'ui', 'top-bar'],
        hideAnimationDuration:0,
        showAnimationDuration: 0
    };
    const projects_lightbox = new PhotoSwipeLightbox(projects_options);
    projects_lightbox.init();

    let custom_tabs = document.querySelector('.js-custom-tabs');
    if (custom_tabs !== null) {
        new CustomTabs(custom_tabs);
    }

    let quantity_inputs = document.querySelectorAll('.js-quantity');
    if (quantity_inputs !== null) {
        quantity_inputs.forEach(qi => {
            new QuantityInput(qi);
            qi.qty_loaded = true;
        });
    }

    let product_movie = document.querySelector('.js-product-movie');
    if (product_movie !== null) {
        new ProductMovie(product_movie);
    }

    let shop_search = document.querySelector('.js-shop-search');
    if (shop_search !== null) {
        new ShopSearch(shop_search);
    }

    let inputFile = document.getElementById( 'file-input' );
    let infoArea = document.getElementById( 'file-upload-filename' );
    let labelFile = document.getElementById( 'file-input-label' );

    if(inputFile){
        inputFile.addEventListener( 'change', showFileName );
    }

    File.prototype.convertToBase64 = function(callback){
        let reader = new FileReader();
        reader.onloadend = function (e) {
            callback(e.target.result, e.target.error);
        };
        reader.readAsDataURL(this);
    };

    function showFileName( event ) {
        let input = event.srcElement;
        let fileName = input.files[0].name;
        infoArea.textContent = fileName;
        labelFile.textContent = 'Zmień plik';
    }

    let contact_form = document.querySelector('.js-contact-form');
    if (contact_form !== null) {
        let loader = contact_form.querySelector('[data-element="loader"]');
        let recaptcha_key = contact_form.getAttribute('data-recaptcha');
        let pristine = new Pristine(contact_form, {
            classTo: 'js-per',
            errorTextParent: 'js-per',
        });

        new Form(contact_form, {
            action: '/wp-json/netivo/v1/contact_form',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        first_name: '',
                        email: '',
                        order_number: '',
                        message: '',
                        file: '',
                        file_name: '',
                        nonce: '',
                        recaptcha: '',
                    };

                    let form_first_name = el.querySelector('[name="first_name"]'),
                        form_email = el.querySelector('[name="email"]'),
                        form_order_number = el.querySelector('[name="order_number"]'),
                        form_message = el.querySelector('[name="message"]'),
                        form_file = el.querySelector('[name="file"]'),
                        form_nonce = el.querySelector('[name="nonce"]');

                    if (form_first_name !== null) data.first_name = form_first_name.value;
                    if (form_email !== null) data.email = form_email.value;
                    if (form_order_number !== null) data.order_number = form_order_number.value;
                    if (form_message !== null) data.message = form_message.value;
                    if (form_nonce !== null) data.nonce = form_nonce.value;

                    if (form_file !== null && form_file.value !== '') {
                        let f = el.querySelector('[name="file"]').files[0];
                        // console.log(f);
                        f.convertToBase64(base64 => {
                            data.file = encodeURIComponent(base64);
                            data.file_name = f.name;

                            grecaptcha.ready(function() {
                                grecaptcha.execute(recaptcha_key, {action: 'contact'}).then(function (token) {
                                    data.recaptcha = token;
                                    resolve(data);
                                });
                            });
                        });
                    } else {
                        grecaptcha.ready(function() {
                            grecaptcha.execute(recaptcha_key, {action: 'contact'}).then(function (token) {
                                data.recaptcha = token;
                                resolve(data);
                            });
                        });
                    }
                });
            },
            beforeSubmit: (el, hashed) => {
                let is_valid = pristine.validate();
                if(is_valid){
                    loader.classList.add('loader--show-in-form');
                    let formResponse = contact_form.querySelector('[data-element="response"]');
                    formResponse.style.display = 'flex';
                } else {
                    let top = el.querySelector('.has-danger').getBoundingClientRect().top + window.pageYOffset;
                    //console.log(top);
                    window.scrollTo({top: top - 100, behavior: "smooth"});
                }
                return is_valid;
            },
            success: (el, response) => {
                let formResponse = contact_form.querySelector('[data-element="response"]');
                let formSuccess = contact_form.querySelector('[data-response="success"]');
                loader.classList.remove('loader--show-in-form');
                formSuccess.innerText = response.message;
                formSuccess.style.display = 'block';
                setTimeout(() => {
                    el.querySelector('[name="first_name"]').value = '';
                    el.querySelector('[name="email"]').value = '';
                    el.querySelector('[name="order_number"]').value = '';
                    el.querySelector('[name="message"]').value = '';
                    el.querySelector('[name="file"]').value = '';
                    el.querySelector('#daneOsobowe').checked = false;
                    formResponse.style.display = 'none';
                }, 6000);
            },
            error: (el, response) => {
                //console.log(response);
                let formResponse = contact_form.querySelector('[data-element="response"]');
                let formError = contact_form.querySelector('[data-response="error"]');
                loader.classList.remove('loader--show-in-form');
                formError.innerText = response;
                setTimeout(() => {
                    formResponse.style.display = 'none';
                }, 6000);
            }
        });
    }

    let ask_trader_form = document.querySelector('.js-ask-trader-form');
    if (ask_trader_form !== null) {
        let recaptcha_key = ask_trader_form.getAttribute('data-recaptcha');
        let pristine = new Pristine(ask_trader_form, {
            classTo: 'js-per',
            errorTextParent: 'js-per',
        });

        new Form(ask_trader_form, {
            action: '/wp-json/netivo/v1/ask_trader_form',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        email: '',
                        amount: '',
                        message: '',
                        product_id: '',
                        recaptcha: '',
                    };

                    let form_email = el.querySelector('[name="email"]'),
                        form_amount = el.querySelector('[name="amount"]'),
                        form_message = el.querySelector('[name="message"]'),
                        form_product_id = el.querySelector('[name="product_id"]'),
                        form_nonce = el.querySelector('[name="nonce"]');

                    if (form_email !== null) data.email = form_email.value;
                    if (form_amount !== null) data.amount = form_amount.value;
                    if (form_message !== null) data.message = form_message.value;
                    if (form_product_id !== null) data.product_id = form_product_id.value;
                    if (form_nonce !== null) data.nonce = form_nonce.value;

                    grecaptcha.ready(function() {
                        grecaptcha.execute(recaptcha_key, {action: 'contact'}).then(function (token) {
                            data.recaptcha = token;
                            resolve(data);
                        });
                    });
                });
            },
            beforeSubmit: (el, hashed) => {
                let is_valid = pristine.validate();
                if(is_valid){
                    ask_trader_form.querySelector('[data-element="loader"]').classList.add('loader--show-in-form');
                    let formResponse = ask_trader_form.querySelector('[data-element="response"]');
                    formResponse.style.display = 'flex';
                } else {
                    let top = el.querySelector('.has-danger').getBoundingClientRect().top + window.pageYOffset;
                    //console.log(top);
                    window.scrollTo({top: top - 100, behavior: "smooth"});
                }
                return is_valid;
            },
            success: (el, response) => {
                let formResponse = ask_trader_form.querySelector('[data-element="response"]');
                let formSuccess = ask_trader_form.querySelector('[data-response="success"]');
                ask_trader_form.querySelector('[data-element="loader"]').classList.remove('loader--show-in-form');
                formSuccess.innerText = response.message;
                formSuccess.style.display = 'block';
                setTimeout(() => {
                    formResponse.style.display = 'none';
                    formSuccess.innerText = '';
                    el.querySelector('[name="email"]').value = '';
                    el.querySelector('[name="amount"]').value = '';
                    el.querySelector('[name="message"]').value = '';
                    el.querySelector('#daneOsobowe').checked = false;
                }, 6000);
            },
            error: (el, response) => {
                let formResponse = ask_trader_form.querySelector('[data-element="response"]');
                let formError = ask_trader_form.querySelector('[data-response="error"]');
                ask_trader_form.querySelector('[data-element="loader"]').classList.remove('loader--show-in-form');
                formError.innerText = response;
                setTimeout(() => {
                    formResponse.style.display = 'none';
                    formError.innerText = ''
                }, 6000);
            }
        });
    }

    let product_available_form = document.querySelector('.js-product-available-form');
    if (product_available_form !== null) {
        let recaptcha_key = product_available_form.getAttribute('data-recaptcha');
        let pristine = new Pristine(product_available_form, {
            classTo: 'js-per',
            errorTextParent: 'js-per',
        });

        new Form(product_available_form, {
            action: '/wp-json/netivo/v1/product_available_form',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        email: '',
                        product_id: '',
                        recaptcha: '',
                    };

                    let form_email = el.querySelector('[name="email"]'),
                        form_product_id = el.querySelector('[name="product_id"]'),
                        form_nonce = el.querySelector('[name="nonce"]');

                    if (form_email !== null) data.email = form_email.value;
                    if (form_product_id !== null) data.product_id = form_product_id.value;
                    if (form_nonce !== null) data.nonce = form_nonce.value;

                    grecaptcha.ready(function() {
                        grecaptcha.execute(recaptcha_key, {action: 'product_available'}).then(function (token) {
                            data.recaptcha = token;
                            resolve(data);
                        });
                    });
                });
            },
            beforeSubmit: (el, hashed) => {
                let is_valid = pristine.validate();
                if(is_valid){
                    product_available_form.querySelector('[data-element="loader"]').classList.add('loader--show-in-form');
                    let formResponse = product_available_form.querySelector('[data-element="response"]');
                    formResponse.style.display = 'flex';
                } else {
                    let top = el.querySelector('.has-danger').getBoundingClientRect().top + window.pageYOffset;
                    window.scrollTo({top: top - 100, behavior: "smooth"});
                }
                return is_valid;
            },
            success: (el, response) => {
                let formResponse = product_available_form.querySelector('[data-element="response"]');
                let formSuccess = product_available_form.querySelector('[data-response="success"]');
                product_available_form.querySelector('[data-element="loader"]').classList.remove('loader--show-in-form');
                formSuccess.innerText = response.message;
                formSuccess.style.display = 'block';
                setTimeout(() => {
                    formResponse.style.display = 'none';
                    formSuccess.innerText = '';
                    el.querySelector('[name="email"]').value = '';
                    el.querySelector('#daneOsobowe').checked = false;
                }, 6000);
            },
            error: (el, response) => {
                let formResponse = product_available_form.querySelector('[data-element="response"]');
                let formError = product_available_form.querySelector('[data-response="error"]');
                product_available_form.querySelector('[data-element="loader"]').classList.remove('loader--show-in-form');
                formError.innerText = response;
                setTimeout(() => {
                    formResponse.style.display = 'none';
                    formError.innerText = ''
                }, 6000);
            }
        });
    }


    let header = document.querySelector('.js-header');
    if (header !== null) {
        new Header(header);
    }

    function getCookie (name) {
        let value = `; ${document.cookie}`;
        let parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    function createHtmlFromString(string) {
        let div = document.createElement('div');
        div.innerHTML = string.trim();
        return div.childNodes;
    }

    if (window.innerWidth <= 991) {
        let mobile_filter_navigation = document.querySelector('.js-mobile-filter-navigation');
        if (mobile_filter_navigation !== null) {
            new MobileFilterNavigation(mobile_filter_navigation);
        }
    }

    let mobile_navigation = document.querySelector('.js-mobile-navigation');
    if (mobile_navigation !== null) {
        new MobileNavigation(mobile_navigation);
    }

    let data_filters = document.querySelectorAll('.js-attribute-filter');
    if (data_filters.length > 0) {
        data_filters.forEach(filter => {
            new Filters(filter);
        });
    }
    let data_filters_hidden = document.querySelectorAll('.js-hidden-filter-options');
    if (data_filters_hidden.length > 0) {
        data_filters_hidden.forEach(filter => {
            new HiddenFilters(filter);
        });
    }

    // let product_variations = document.querySelectorAll('.js-product-variations');
    // if (product_variations !== null) {
    //     product_variations.forEach(variation => {
    //         new ProductVariations(variation);
    //     });
    // }

    let ask_trader = document.querySelector('.js-ask-trader');
    if (ask_trader !== null) {
        new AskTrader(ask_trader);
    }

    let product_available = document.querySelector('.js-product-available-button');
    if (product_available !== null) {
        new ProductAvailable(product_available);
    }

    let banners = document.querySelectorAll('.js-banners');
    if (banners !== null) {
        banners.forEach(banner => {
            new Banners(banner);
        });
    }

    let promo_bars = document.querySelectorAll('.js-promo-bars');
    if (promo_bars !== null) {
        promo_bars.forEach(pb => {
            new PromoBars(pb);
        });
    }

    let fv = document.querySelector('.js-fv-checkbox');
    if (fv !== null) {
        let fields_to_show = fv.closest('.box__content').querySelectorAll('.js-fvat-to-show');
        if (fields_to_show !== null) {
            fields_to_show.forEach(field => {
                field.style.display = 'none';
            });
        }
        let fields_to_hide = fv.closest('.box__content').querySelectorAll('.js-fvat-to-hide');
        if (fields_to_hide !== null) {
            fields_to_hide.forEach(field => {
                let label = field.querySelector('label');
                if (label !== null) {
                    let optional = label.querySelector('span');
                    if (optional !== null) {
                        optional.remove();
                        let abbr = document.createElement('abbr');
                        abbr.classList.add('required');
                        abbr.setAttribute('title', 'wymagane');
                        abbr.innerText = '*';
                        label.appendChild(abbr);
                    }
                }
            });
        }
        fv.addEventListener('change', () => {
             if (fv.checked) {
                 fields_to_show.forEach(field => {
                     field.style.display = 'block';

                     if (!field.classList.contains('validate-required')) {
                         field.classList.add('validate-required');
                     }

                     let label = field.querySelector('label');
                     if (label !== null) {
                         let optional = label.querySelector('span');
                         if (optional !== null) {
                             optional.remove();
                             let abbr = document.createElement('abbr');
                             abbr.classList.add('required');
                             abbr.setAttribute('title', 'wymagane');
                             abbr.innerText = '*';
                             label.appendChild(abbr);
                         }
                     }

                     let input = field.querySelector('input');
                     if (input !== null) {
                         let placeholder_str = input.getAttribute('placeholder');
                         if (!placeholder_str.includes('*')) {
                             placeholder_str = placeholder_str + '*';
                         }
                         input.setAttribute('placeholder', placeholder_str);
                     }
                 });

                 if (fields_to_hide !== null) {
                     fields_to_hide.forEach(field => {
                         if (field.classList.contains('validate-required')) {
                             field.classList.remove('validate-required');
                         }

                         let label = field.querySelector('label');
                         if (label !== null) {
                             let abbr = label.querySelector('abbr');
                             if (abbr !== null) {
                                 abbr.remove();
                                 let optional = document.createElement('span');
                                 optional.classList.add('optional');
                                 optional.innerText = '(opcjonalne)';
                                 label.appendChild(optional);
                             }
                         }

                         let input = field.querySelector('input');
                         if (input !== null) {
                             let placeholder_str = input.getAttribute('placeholder');
                             placeholder_str = placeholder_str.replace('*', '');
                             input.setAttribute('placeholder', placeholder_str);
                         }
                     });
                 }
             } else {
                 fields_to_show.forEach(field => {
                     field.style.display = 'none';
                 });

                 if (fields_to_hide !== null) {
                     fields_to_hide.forEach(field => {
                         if (!field.classList.contains('validate-required')) {
                             field.classList.add('validate-required');
                         }

                         let label = field.querySelector('label');
                         if (label !== null) {
                             let optional = label.querySelector('span');
                             if (optional !== null) {
                                 optional.remove();
                                 let abbr = document.createElement('abbr');
                                 abbr.classList.add('required');
                                 abbr.setAttribute('title', 'wymagane');
                                 abbr.innerText = '*';
                                 label.appendChild(abbr);
                             }
                         }

                         let input = field.querySelector('input');
                         if (input !== null) {
                             let placeholder_str = input.getAttribute('placeholder');
                             placeholder_str = placeholder_str + '*';
                             input.setAttribute('placeholder', placeholder_str);
                         }
                     });
                 }
             }
        });
    }

    let continue_shopping = document.querySelector('.js-go-back');
    if (continue_shopping !== null) {
        continue_shopping.addEventListener('click', e => {
            e.preventDefault();

            history.back();
        });
    }

    let newsletter_modal = document.querySelector('.js-newsletter-modal');
    if (newsletter_modal !== null) {
        let close = newsletter_modal.querySelector('[data-element="close"]');
        if (close !== null) {
            close.addEventListener('click', e => {
                e.preventDefault();

                if (newsletter_modal.classList.contains('modal--shown')) {
                    newsletter_modal.classList.remove('modal--shown');
                }
            });
        }
    }

    // let cart_newsletter = document.querySelector('.js-cart-newsletter');
    // if (cart_newsletter !== null) {
    //     new CartNewsletter(cart_newsletter);
    // }

    let gallerySalonCarousel = document.querySelector('.js-salon-gallery-carousel');
    if (gallerySalonCarousel !== null) {
        let breakpoints = {
            1000: {
                perPage: 4
            },
            810: {
                perPage: 3
            },
            767: {
                perPage: 2
            },
            580: {
                perPage: 2
            }
        };

        let options = {
            perPage: 6,
            rewind: true,
            gap: 15,
            pagination: false,
            arrows: false,
            autoplay: 3000,
            breakpoints: breakpoints
        };

        new Splide(gallerySalonCarousel, options).mount();
    }

    let projects_grids = document.querySelectorAll('.js-project-grid');
    if (projects_grids.length > 0) {
        projects_grids.forEach(grid => {
            new ProjectGrid(grid);
        });
    }

    let inspiration_filters = document.querySelector('.js-inspiration-filters');
    if (inspiration_filters !== null) {
        new InspirationFilters(inspiration_filters);
    }

    let inspiration_checkboxes = document.querySelectorAll('.js-checkbox-list');
    if (inspiration_checkboxes.length > 0) {
        inspiration_checkboxes.forEach(checkboxes => {
            new InspirationCheckboxes(checkboxes);
        });
    }


}, { once: true });

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 100;
    if (document.body.clientWidth <= 600) {
        window_top = document.body.scrollTop - 50;
    }
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});

document.addEventListener('click', function(e) {
    let globalModal = document.querySelector('.js-global-modal');
    if (globalModal !== null) {
        const isClickInside = globalModal.contains(e.target);
        if (!isClickInside) {
            if (globalModal.classList.contains('modal--shown')) {
                globalModal.classList.remove('modal--shown');
            }
        }
    }
});