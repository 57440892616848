class BlogCategories {
    constructor(selector) {
        this.selector = selector;
        this.button = this.selector.querySelector('[data-element="button"]');

        if (this.button !== null) {
            this._clickButton = this._clickButton.bind(this);
            this.button.addEventListener('click', this._clickButton);
        }
    }
    _clickButton(e) {
        e.preventDefault();

        if (this.selector.classList.contains('blog-categories--expand')) {
            this.selector.classList.remove('blog-categories--expand');
        } else this.selector.classList.add('blog-categories--expand');
    }
}
export default BlogCategories;