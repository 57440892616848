class MobileFilterNavigation {
    constructor(selector) {
        this.selector = selector;
        this.filter_button = this.selector.querySelector('[data-element="button"]');
        this.filter_widget = document.querySelector('.js-filter-box');
        //console.log(this.filter_widget);

        this.filter_close_button = document.createElement('a');
        this.filter_close_button.setAttribute('data-element', 'close');
        this.filter_close_button.setAttribute('href', '');
        this.filter_close_button.classList.add('box__button-close');
        this.filter_close_button.innerText = '×';

        this.filter_widget.appendChild(this.filter_close_button);

        if (this.filter_button !== null) {
            this._filterButtonEvent = this._filterButtonEvent.bind(this);
            this.filter_button.addEventListener('click', this._filterButtonEvent);
        }

        if (this.filter_close_button !== null) {
            this._filterCloseButtonEvent = this._filterCloseButtonEvent.bind(this);
            this.filter_close_button.addEventListener('click', this._filterCloseButtonEvent);
        }

        if (window.innerWidth <= 991) {
            document.body.style.paddingBottom = this.selector.offsetHeight + 'px';
        }
    }
    _filterButtonEvent(e) {
        e.preventDefault();

        if (this.selector.classList.contains('shown')) {
            this.selector.classList.remove('shown');
            this.filter_widget.classList.remove('shown');
            // document.querySelector('.js-sticky-navbar').style.zIndex = 21;
            document.querySelector('.js-header').style.zIndex = 21;

            document.body.style.overflow = 'auto';
        } else {
            this.selector.classList.add('shown');
            this.filter_widget.classList.add('shown');

            // document.querySelector('.js-sticky-navbar').style.zIndex = 20;
            document.querySelector('.js-header').style.zIndex = 20;

            document.body.style.overflow = 'hidden';
        }
    }
    _filterCloseButtonEvent(e) {
        e.preventDefault();

        if (this.filter_widget.classList.contains('shown')) {
            this.filter_widget.classList.remove('shown');
            // document.querySelector('.js-sticky-navbar').style.zIndex = 21;

            document.body.style.overflow = 'auto';
        }
    }
}
export default MobileFilterNavigation;