class CustomTabs {
    constructor(selector) {
        this.selector = selector;
        this.parent = this.selector.parentNode;
        this.list = this.selector.querySelector('[data-element="list"]');
        this.content = this.selector.querySelector('[data-element="content"]');
        this.product_sticky_bar = document.body.querySelector('.js-product-sticky-bar');
        this.screen_width = window.innerWidth;
        this.header_tabs = document.querySelector('.js-psb-tabs');
        this.sticky_sidebar = document.querySelector('.js-sticky-sidebar');

        this.last_scrolling_height = 0;
        this.current_scrolling_height = 0;
        this.scroll_top = 0;
        this.max_range = 0;
        this.box_width = 0;

        if (this.list !== null) {
            this.box_width = this.list.clientWidth;
            if (this.content !== null) {
                let tabs = this.list.querySelectorAll('a');
                if (tabs !== null) {
                    tabs.forEach(tab => {
                        tab.addEventListener('click', e => {
                            e.preventDefault();

                            let target = tab.getAttribute('data-target');
                            let section = document.getElementById(target);
                            let section_position = window.pageYOffset +  section.getBoundingClientRect().top - 65;
                            window.scrollTo({
                                top: section_position - (this.product_sticky_bar.offsetHeight + 5),
                                behavior: 'smooth'
                            });
                            this._clearTabs();
                            e.target.classList.add('active');
                            this._syncHeaderActiveTabs(target);
                        });
                    });
                }
                if (window.innerWidth > 810) {
                    window.addEventListener('scroll', () => {
                        this.current_scrolling_height =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                        this.scroll_top = document.documentElement.scrollTop || document.body.scrollTop;
                        // console.log(parseInt(this.current_scrolling_height) + parseInt(this.list.offsetHeight) + parseInt(33), parseInt(this.max_range));
                        this.max_range = window.pageYOffset + this.parent.getBoundingClientRect().top + this.parent.offsetHeight;

                        if (this.last_scrolling_height > this.current_scrolling_height) {
                            if ((parseInt(this.current_scrolling_height) + parseInt(this.list.offsetHeight) + parseInt(33) <= parseInt(this.max_range))) {
                                this.list.classList.remove('absolute');
                                this.list.classList.add('sticky')
                                this.product_sticky_bar.classList.add('show');
                            }
                            if (this.current_scrolling_height < (window.pageYOffset + this.parent.getBoundingClientRect().top - 45)) {
                                this.list.removeAttribute('style');
                                this.list.classList.remove('sticky');
                                this.product_sticky_bar.classList.remove('show');
                                this.sticky_sidebar.classList.remove('top-135');
                            }
                        } else if (this.current_scrolling_height > this.last_scrolling_height) {
                            if ((parseInt(this.current_scrolling_height) + parseInt(this.list.offsetHeight) + parseInt(0) >= parseInt(this.max_range))) {
                                this.list.classList.remove('sticky');
                                // this.sticky_sidebar.classList.remove('top-165');
                                this.list.classList.add('absolute');

                            } else if (this.current_scrolling_height > (window.pageYOffset + this.parent.getBoundingClientRect().top - 45)) {
                                this.list.setAttribute('style', 'width: ' + this.box_width + 'px !important;');
                                this.list.classList.add('sticky');
                                this.product_sticky_bar.classList.add('show');
                                this.sticky_sidebar.classList.add('top-135');
                            }
                        }

                        let tabs = this.content.querySelectorAll('[id^="tab-"]');
                        let header_tabs = this.header_tabs.querySelectorAll('[data-target]');
                        if (tabs !== null) {
                            tabs.forEach(tab => {
                                let id = tab.getAttribute('id');
                                if ((window.pageYOffset + tab.getBoundingClientRect().top - (2 * this.product_sticky_bar.offsetHeight))  <= this.scroll_top) {
                                    header_tabs.forEach(tab => {
                                        tab.parentNode.classList.remove('active');
                                    });
                                    this.header_tabs.querySelector('[data-target="' + id + '"]').parentNode.classList.add('active');
                                    // console.log('1 ' + this.header_tabs.querySelector('[data-target="' + id + '"]') + ' ' + id);
                                }
                            });
                        }

                        this.last_scrolling_height = this.current_scrolling_height;
                    });

                    // let tabs = this.content.querySelectorAll('[id^="tab-"]');
                    // if (tabs !== null) {
                    //     tabs.forEach(tab => {
                    //         // console.log(tab.getBoundingClientRect().top + document.documentElement.scrollTop);
                    //
                    //         // console.log(this.current_scrolling_height, ((tab.offsetTop - (2* this.sticky_sidebar.offsetHeight)) && this.scroll_top <= tab.offsetTop + tab.offsetHeight));
                    //         if (this.scroll_top >= (tab.getBoundingClientRect().top + document.documentElement.scrollTop - (2* this.sticky_sidebar.offsetHeight)) && this.scroll_top <= tab.getBoundingClientRect().top + document.documentElement.scrollTop) {
                    //             // console.log(tab.getAttribute('id'));
                    //
                    //             tabs.forEach(tab => {
                    //                 tab.classList.remove('active');
                    //             });
                    //             tabs.querySelector('[data-target="' + tab.getAttribute('id') + '"]').parentNode.classList.add('active');
                    //         } else tabs.querySelector('[data-target="' + tab.getAttribute('id') + '"]').parentNode.classList.remove('active');
                    //     });
                    // }
                } else {
                    let mobile_tabs_content = this.content.querySelectorAll('[data-element="mobile-tab-content"]');
                    if (mobile_tabs_content !== null) {
                        mobile_tabs_content.forEach(mtc => {
                            mtc.style.display = 'none';
                        });
                    }

                    let mobile_clickers = this.content.querySelectorAll('[data-element="mobile-clicker"]');
                    if (mobile_clickers !== null) {
                        mobile_clickers.forEach(mc => {
                            mc.classList.remove('open');
                            mc.addEventListener('click', e => {
                                e.preventDefault();

                                let target = mc.parentNode;
                                let target_content = target.parentNode.querySelector('[data-element="mobile-tab-content"]');
                                if (target.classList.contains('open')) {
                                    target.classList.remove('open');
                                    target_content.style.display = 'none';
                                } else {
                                    target.classList.add('open');
                                    target_content.style.display = 'block';
                                }
                            });
                        });
                    }

                    let mobile_tabs = this.content.querySelectorAll('[data-element="mobile-tab"]');
                    if (mobile_tabs !== null) {
                        mobile_tabs.forEach(mt => {
                            mt.addEventListener('click', e => {
                                e.preventDefault();

                                let target = e.target;
                                let target_content = target.parentNode.querySelector('[data-element="mobile-tab-content"]');
                                if (target.classList.contains('open')) {
                                    target.classList.remove('open');
                                    target_content.style.display = 'none';
                                } else {
                                    target.classList.add('open');
                                    target_content.style.display = 'block';
                                }
                            });
                        });
                    }
                }
            }
            if (this.header_tabs !== null) {
                let tabs = this.header_tabs.querySelectorAll('a');
                if (tabs !== null) {
                    tabs.forEach(tab => {
                        tab.addEventListener('click', e => {
                            e.preventDefault();

                            let target = tab.getAttribute('data-target');
                            let section = document.getElementById(target);
                            let section_position = window.pageYOffset +  section.getBoundingClientRect().top;
                            //console.log('header ' + this.product_sticky_bar.offsetHeight);
                            window.scrollTo({
                                top: section_position - (this.product_sticky_bar.offsetHeight),
                                behavior: 'smooth'
                            });

                            this._clearHeaderTabs();
                            e.target.parentNode.classList.add('active');
                            this._syncContentActiveTabs(target);
                        });
                    });
                }
            }
        }
    }
    _clearHeaderTabs() {
        this.header_tabs.querySelectorAll('li').forEach(tab => {
            if (tab.classList.contains('active')) {
                tab.classList.remove('active');
            }
        });
    }
    _clearTabs() {
        this.list.querySelectorAll('li').forEach(tab => {
            if (tab.classList.contains('active')) {
                tab.classList.remove('active');
            }
        });
    }
    _syncHeaderActiveTabs(target) {
        //console.log(target);
        let header_tabs = this.header_tabs.querySelectorAll('a');
        //console.log(header_tabs);
        header_tabs.forEach(tab => {
            tab.parentNode.classList.remove('active');
        });
        let tab = this.header_tabs.querySelector('[data-target="' + target + '"]');
        tab.parentNode.classList.add('active');
    }

    _syncContentActiveTabs(target) {
        //console.log(target);
        let content_tabs = this.list.querySelectorAll('a');
        content_tabs.forEach(tab => {
            tab.parentNode.classList.remove('active');
        });
        let tab = this.list.querySelector('[data-target="' + target + '"]');
        tab.parentNode.classList.add('active');
    }
}
export default CustomTabs;