class StickySidebar {
    constructor(selector) {
        this.selector = selector;
        this.parent = this.selector.parentNode;

        this.last_scrolling_height = 0;
        this.current_scrolling_height = 0;
        this.max_range = 0;
        this.box_width = 0;

        this.box_width = 330;

        if (window.innerWidth > 810) {
            this._watchScrolling = this._watchScrolling.bind(this);

            window.addEventListener('scroll', this._watchScrolling);
            window.addEventListener('resize', this._watchScrolling);
        }
    }
    _watchScrolling() {
        this.current_scrolling_height =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.max_range = window.pageYOffset + this.parent.getBoundingClientRect().top + this.parent.offsetHeight;

        if (this.last_scrolling_height > this.current_scrolling_height) {
            if ((parseInt(this.current_scrolling_height) + parseInt(this.selector.offsetHeight) + parseInt(33)  <= parseInt(this.max_range))) {
                this.selector.classList.remove('absolute');
                this.selector.classList.add('sticky')
            }
            if (this.current_scrolling_height < (window.pageYOffset + this.parent.getBoundingClientRect().top - 68)) {
                this.selector.removeAttribute('style');
                this.selector.classList.remove('sticky');
            }
        } else if (this.current_scrolling_height > this.last_scrolling_height) {
            if ((parseInt(this.current_scrolling_height) + parseInt(this.selector.offsetHeight) + parseInt(33) >= parseInt(this.max_range))) {
                this.selector.classList.remove('sticky');
                this.selector.classList.add('absolute');
            } else if (this.current_scrolling_height > (window.pageYOffset + this.parent.getBoundingClientRect().top - 68)) {
                this.selector.setAttribute('style', 'width: ' + (this.box_width - 70) + 'px !important;');
                this.selector.classList.add('sticky');
            }
        }
        this.last_scrolling_height = this.current_scrolling_height;
    }
}
export default StickySidebar;