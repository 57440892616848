class QuantityInput {
    constructor(selector) {
        this.selector = selector;
        this.quantity_minus = this.selector.querySelector('[data-button-minus]');
        this.quantity_plus = this.selector.querySelector('[data-button-plus]');
        this.step_value = this.selector.querySelector('[step]').getAttribute('step');
        this.quantity_input = this.selector.querySelector('[data-element="product-qty"]');

        this.mInBox = this.selector.querySelector('[data-element="meters-in-box"]');
        if (this.mInBox !== null) {
            this.mInBoxVal = this.mInBox.getAttribute('data-amount');
            this._updateMeters(this.mInBoxVal, this.quantity_input);
        }

        if (this.quantity_minus !== null) {
            this._actionMinus = this._actionMinus.bind(this);
            this.quantity_minus.addEventListener('click', this._actionMinus);
        }

        if (this.quantity_plus !== null) {
            this._actionPlus = this._actionPlus.bind(this);
            this.quantity_plus.addEventListener('click', this._actionPlus);
        }
    }
    _actionMinus(e) {
        e.preventDefault();

        let sel = e.target.getAttribute('data-quantity');
        let qty_element = document.getElementById(sel);

        if (qty_element !== null) {
            let current_qty = parseInt(qty_element.value);
            let new_qty = Math.max(1, current_qty - this.step_value);
            qty_element.value = new_qty;
            let event = new Event('change');
            qty_element.dispatchEvent(event);
            if(this.mInBox !== null) {
                this._updateMeters(this.mInBoxVal, qty_element)
            }
        }


        let cart_btn = document.body.querySelector('button[name="update_cart"]');
        if (cart_btn.getAttribute('disabled') !== null) {
            cart_btn.removeAttribute('disabled');
        }
    }
    _actionPlus(e) {
        e.preventDefault();

        let sel = e.target.getAttribute('data-quantity');
        let qty_element = document.getElementById(sel);

        if (qty_element !== null) {
            let current_qty = parseInt(qty_element.value);
            let new_qty = current_qty + parseInt(this.step_value);
            let max_qty = qty_element.getAttribute('max');

            if (max_qty) {
                new_qty = Math.min(new_qty, max_qty);
            }
            qty_element.value = new_qty;
            let event = new Event('change');
            qty_element.dispatchEvent(event);

            let cart_btn = document.body.querySelector('button[name="update_cart"]');
            if (cart_btn !== null) {
                if (cart_btn.getAttribute('disabled') !== null) {
                    cart_btn.removeAttribute('disabled');
                }
            }
            if(this.mInBox !== null) {
                this._updateMeters(this.mInBoxVal, qty_element)
            }
        }
    }
    _updateMeters(meters, quantity) {
        let qty = quantity.value;
        let tmp_qty = parseFloat(qty.replace(',', '.'));
        let tmp_meters = parseFloat(meters.replace(',', '.'));

        this.mInBox.innerHTML = (tmp_qty*tmp_meters).toFixed(2) + 'm<sup>2</sup>';
    }
}
export default QuantityInput;