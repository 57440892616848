import InspirationProducts from "./inspirationProducts";

class Inspirations {
    constructor(selector) {
        this.selector = selector;
        this.postContainer = this.selector.querySelector('[data-element="container"]');
        this.loader = this.selector.querySelector('[data-element="loader"]');
        this.categories_container = null;
        this.cats = null;
        this.button = this.selector.querySelector('[data-element="button"]');
        this.pointBoxWith = '320px';

        if (this.selector.querySelector('[data-element="categories"]') !== null) {
            this.categories_container = this.selector.querySelector('[data-element="categories"]');
            if (this.categories_container !== null) {
                this.cats = this.categories_container.querySelectorAll('[data-id]')
            }
        }

        this.isLoading = false;
        this._loadAttributes();

        if (this.loadOnInit) {
            this.postContainer.innerHTML = '';
            this._loadPosts();
        }

        if (this.button !== null) {
            this._nextPageEvent = this._nextPageEvent.bind(this);
            this.button.addEventListener('click', this._nextPageEvent);
        }

        if (this.cats !== null) {
            this._clickCategory = this._clickCategory.bind(this);
            this.cats.forEach(cat => {
                cat.addEventListener('click', this._clickCategory);
            });
        }
    }
    _getFromAPI(){
        let queryString = this._buildQuery();
        let url = '/wp-json/wp/v2/inspiration?' + queryString;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _loadAttributes(){
        let perPage = this.selector.getAttribute('data-per-page');
        if(perPage !== null && perPage !== ''){
            this.perPage = parseInt(perPage);
        } else this.perPage = 10;

        let loadOnInit = this.selector.getAttribute('data-load-on-init');
        if(loadOnInit !== null && loadOnInit !== ''){
            this.loadOnInit = (loadOnInit === 'true');
        } else this.loadOnInit = false;

        let page = this.selector.getAttribute('data-page');
        if(page !== null && page !== ''){
            this.page = parseInt(page);
        } else this.page = 1;

        let categories = this.selector.getAttribute('data-category');
        if(categories !== null && categories !== ''){
            this.categories = categories.split(',');
        } else this.categories = [];

        let type = this.selector.getAttribute('data-type');
        if (type !== null && type !== '') {
            this.type = type;
        } else this.type = 'standard';

        let max = this.selector.getAttribute('data-max');
        if (max !== null && max !== '') {
            this.max = max;
        }
    }
    _buildQuery(){
        let query = [
            'per_page=' + this.perPage,
            'page=' + this.page
        ];

        if (this.categories !== null) {
            if (this.categories == '-1') {
                this.categories = [];
            }
            if(this.categories.length > 0){
                query.push('insp_cat=' + this.categories.join(','));
            }
        }

        if (this.type === 'similar') {
            let current_post_id = this.selector.getAttribute('data-post-id');
            query.push('exclude[0]=' + current_post_id);
        }

        query.push('_embed');
        return query.join('&');
    }

    _loadPosts() {
        this.postContainer.style.minHeight = '456px';
        this._showLoaderAnimation();
        this.isLoading = true;
        this._getFromAPI().then(response => {
            if(response.length > 0){
                // console.log('generate');
                this._generatePosts(response);
            } else {
                // console.log('no-results');
                this._noResults();
            }
        }).catch(error => {
            this._handleError(error);
        })
    }
    _nextPageEvent(event){
        event.preventDefault();
        if(!this.lastPage) {
            this._nextPage();
        }
    }

    _nextPage(){
        this.page++;
        this._loadPosts();
    }

    _generatePosts(posts){
        if (this.postContainer.classList.contains('inspirations__container--not-found')) {
            this.postContainer.classList.remove('inspirations__container--not-found');
        }
        posts.forEach((post, key) => {
            let postElement = this._generateInspirationBlock(post);
            this.postContainer.append(postElement);

            new InspirationProducts(postElement);
        });

        if (this.button !== null) {
            let current_count_posts = this.postContainer.querySelectorAll('.inspirations__single-inspiration').length;
            if (current_count_posts < this.page*this.perPage) {
                this.button.style.display = 'none';
            }
        }

        let count = this.postContainer.querySelectorAll('.inspirations__single-inspiration');
        if (count.length <= 3) {
            this.postContainer.style.minHeight = '456px';
        }

        this._hideLoaderAnimation();
        this.postContainer.style.minHeight = 'initial';
        this.isLoading = false;

        // let postContainerWidth = this.postContainer.offsetWidth;
        // count.forEach(item => {
            // console.log(item.style.left);
        // });
        // console.log(postContainerWidth)
    }
    _generateInspirationBlock(post) {
        let item = document.createElement('a');
        item.classList.add('inspirations__single-inspiration', 'js-inspiration');
        item.setAttribute('href', post.link);

        let image_url = null;
        if (post.nt_editor !== null) {
            if (post.nt_editor.image !== null && post.nt_editor.image !== '') {
                image_url = post.nt_editor.image;
            }
        }
        let image = null;
        if (image_url !== null) {
            image = document.createElement('img');
            image.classList.add('inspirations__single-image');
            image.setAttribute('alt', post.title.rendered);
            image.setAttribute('src', image_url);
        }

        let points_container = document.createElement('div');
        points_container.classList.add('inspirations__single-points-container');

        let points_data = post.nt_points;
        if (points_data !== null) {
            for (const id in points_data) {
                let product_id = id;
                let pos = points_data[id].split(',');
                let point = this._createProductPoint(product_id, pos, post.title.rendered);

                points_container.appendChild(point);
            }
        }

        item.appendChild(image);
        item.appendChild(points_container);
        return item;
    }

    _showLoaderAnimation() {
        this.loader.classList.add('loader--shown');
    }
    _hideLoaderAnimation() {
        this.loader.classList.remove('loader--shown');
    }
    _noResults(){
        if (this.type === 'examples') {
            this.postContainer.style.minHeight = '456px';
            let text = document.createElement('p');
            text.innerHTML = 'Brak inspiracji spełniających kryteria.';
            this.postContainer.classList.add('inspirations__container--not-found');
            this.postContainer.appendChild(text);
            this._hideLoaderAnimation();
        } else {
            if(this.page === 1){
                this.postContainer.style.minHeight = '456px';
                let text = document.createElement('p');
                text.innerHTML = 'Brak inspiracji spełniających kryteria.';
                this.postContainer.classList.add('inspirations__container--not-found');
                this.postContainer.appendChild(text);
                this._hideLoaderAnimation();
            } else {
                this.lastPage = true;
                this._hideLoadMoreButton();
            }
        }
    }
    _handleError(error){
        console.log(error);
        if (error.message === 'rest_post_invalid_page_number') {
            this.lastPage = true;
            this._hideLoadMoreButton();
        } else {
            let text = document.createElement('p');
            text.innerHTML = 'Wystąpił błąd poczas ładowania inspiracji.';
            this.postContainer.classList.add('inspirations__container--not-found');
            this.postContainer.appendChild(text);
            this._hideLoaderAnimation();
            this.isLoading = false;
        }
    }
    _hideLoadMoreButton() {
        if (this.button !== null) {
            this.button.style.display = 'none';
        }
    }
    _clickCategory(e) {
        e.preventDefault();

        let cat_id = e.target.getAttribute('data-id');
        if (cat_id !== null) {
            this.categories = [cat_id];
            this._clearActiveCategories();
            this._setActiveCategory(cat_id);

            this.postContainer.innerHTML = '';
            this._loadPosts();
        } else this.categories = [];
    }
    _createProductPoint(id, pos, inspirationName) {
        let point = document.createElement('div');
        point.classList.add('inspiration-point', 'inspiration-point--shown');
        point.setAttribute('data-element', 'product-point');
        point.setAttribute('product-id', id);

        point.style.left = pos[0] + '%';
        point.style.top = pos[1] + '%';

        let math_value = Math.round(pos[0]);
        if (math_value < 40) {
            point.classList.add('inspiration-point--box-right');
        } else if (math_value >= 41 && math_value <= 60) {
            point.classList.add('inspiration-point--box-center');
        } else if (math_value >= 61) {
            point.classList.add('inspiration-point--box-left');
        }

        let box = document.createElement('div');
        box.classList.add('inspiration-point__box');

        let loader = document.createElement('div');
        loader.classList.add('loader', 'loader--circle', 'loader--center', 'loader--static', 'loader--shown');
        loader.setAttribute('data-element', 'point-loader');

        let div1 = document.createElement('div');
        let div2 = document.createElement('div');
        let div3 = document.createElement('div');
        let div4 = document.createElement('div');

        loader.appendChild(div1);
        loader.appendChild(div2);
        loader.appendChild(div3);
        loader.appendChild(div4);

        box.appendChild(loader);

        point.appendChild(box);
        return point;
    }
    _setActiveCategory(cat_id) {
        let category = this.categories_container.querySelector('[data-id="' + cat_id + '"]');
        if (category !== null) {
            let parent = category.parentNode;
            if (parent !== null) {
                parent.classList.add('inspirations__categories-item--active');
            }
        }
    }
    _clearActiveCategories() {
        this.cats.forEach(cat => {
            cat.parentNode.classList.remove('inspirations__categories-item--active');
        });
    }
}
export default Inspirations;