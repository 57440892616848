class CategoryDescription {
    constructor(selector) {
        this.selector = selector;
        this.button = this.selector.querySelector('[data-element="button"]');

        if (this.button !== null) {
            this._clickButton = this._clickButton.bind(this);
            this.button.addEventListener('click', this._clickButton);
        }

        if (window.innerWidth <= 767) {
            if (this.selector.classList.contains('category-description--shown')) {
                this.selector.classList.remove('category-description--shown');
            }
        }
        if (window.innerWidth <= 640) {
            this.button.innerText = 'Rozwiń';
        }
    }
    _clickButton(e) {
        e.preventDefault();

        if (this.selector.classList.contains('category-description--shown')) {
            this.selector.classList.remove('category-description--shown');
            this.button.innerText = 'Rozwiń';
        } else {
            this.selector.classList.add('category-description--shown');
            this.button.innerText = 'Zwiń';
        }
    }
}
export default CategoryDescription;