class ProjectGrid {
    constructor(selector) {
        this.selector = selector;
        this.rows = this.selector.querySelectorAll('[data-element="row"]');
        this.button = this.selector.querySelector('[data-element="button"]');

        console.log('Rows ' + this.rows);

        if (this.button !== null) {
            this._onClick = this._onClick.bind(this);
            this.button.addEventListener('click', this._onClick);
        }
    }
    _onClick(e) {
        e.preventDefault();

        if (this.button.classList.contains('active')) {
            if (this.rows !== null) {
                this.rows.forEach(row => {
                    row.style.display = 'none';
                });
            }
            this.button.innerText = 'Rozwiń';
            this.button.classList.remove('active');
        } else {
            if (this.rows !== null) {
                this.rows.forEach(row => {
                    row.style.display = 'flex';
                });
            }
            this.button.innerText = 'Zwiń';
            this.button.classList.add('active');
        }


    }
}
export default ProjectGrid;